import App from "./App";
import ContextProvider from "./context/context-provider";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-app-polyfill/stable';

Sentry.init({
	dsn: "https://ddffc331fe4c484d9d561d64aca8bbc9@o628183.ingest.sentry.io/5754771",
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

if (document.getElementById('root').childNodes.length) {
	ReactDOM.hydrate(
		<React.StrictMode>
			<ContextProvider>
				<App />
			</ContextProvider>
		</React.StrictMode>,
		document.getElementById("root")
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<ContextProvider>
				<App />
			</ContextProvider>
		</React.StrictMode>,
		document.getElementById("root")
	);
}

serviceWorkerRegistration.register();