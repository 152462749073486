/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import MuiAppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SearchIcon from "@material-ui/icons/Search";
import UpdateIcon from '@material-ui/icons/Update';
import preval from "preval.macro";
import React from "react";
import {
  Link as RouterLink
} from "react-router-dom";
import "../App.css";
import "../css/fade.css";
import Feedback from "./feedback";
import Search from "./search";
import styles from "./css/appbar.module.css"

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // minHeight: "100%",
    // height: "auto",
    minHeight: "100%",
    // backgroundColor: theme.palette.background.default,
    // paddingBottom: 100
  },
  appBar: {
    zIndex: 9,
  },
  toolbar: {
    width: "100%",
  },
  toolbarButtons: {},
  // toolbarButton: {
  // 	// marginRight: theme.spacing(1),
  // 	// marginLeft: theme.spacing(1),
  // 	color: theme.palette.primary.contrastText
  // },
  // avatar: {
  // 	width: theme.spacing(6),
  // 	height: theme.spacing(6),
  // 	marginRight: theme.spacing(1),
  // 	marginLeft: theme.spacing(1),
  // 	cursor: "pointer",
  // },
  // toolbarRight: {
  // 	display: "flex",
  // 	alignItems: "center",
  // 	marginLeft: "auto"
  // },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  sidebar: {
    height: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  mainContent: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    width: "100%",
  },
  label: {
    // fontFamily: 'fieldwork, sans-serif',
    marginBottom: "-2px",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  popover: {
    // background: theme.custom.palette.profilePopColor,
    width: theme.spacing(40),
    // backgroundColor: "rgba(255, 255, 255, .15)",
    // backdropFilter: "blur(5px)",
    // backgroundColor: "rgba(100, 50, 180, 0.9)",
    // backgroundColor: "#3b0087"
    // borderRadius: theme.shape.borderRadius
  },
  container: {
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: "2px solid " + theme.palette.secondary.main,
    margin: theme.spacing(1),
    // background: theme.palette.background.default
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  userName: {
    // ...theme.custom.fontFamily.metropolis,
    fontSize: "1rem",
    fontWeight: 500,
  },
  userEmail: {
    // ...theme.custom.fontFamily.roboto,
    fontSize: "0.9rem",
  },
  bar: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSignout: {
    // ...theme.custom.fontFamily.metropolis,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 2),
    fontSize: "0.8rem",
    fontWeight: 500,
    textTransform: "none",
  },
  textbox: {
    // ...theme.custom.fontFamily.metropolis,
    color: 'white'
  },
}));

// const ListboxComponent = React.forwardRef(function ListboxComponent(
// 	props,
// 	ref
// ) {
// 	const { children, role, ...other } = props;
// 	const itemCount = Array.isArray(children) ? children.length : 0;
// 	const itemSize = 60;

// 	return (
// 		<div ref={ref}>
// 			<div {...other}>
// 				<List
// 					height={600}
// 					width={900}
// 					// style={{ width: '900px', height: '600px' }}
// 					rowHeight={itemSize}
// 					overscanCount={5}
// 					rowCount={itemCount}
// 					rowRenderer={props => {
// 						return React.cloneElement(children[props.index], {
// 							style: props.style
// 						});
// 					}}
// 					role={role}
// 				/>
// 			</div>
// 		</div>
// 	);
// });

export default function AppBar(props) {
  const classes = useStyles()
  const [searching, setSearching] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = window;
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const isMobile = props.isMobile;
  const [skipIntroOutro, setSkipIntoOutro] = React.useState(
    JSON.parse(localStorage.skipOpeningClosing || "false")
  );

  React.useEffect(() => {
    localStorage.skipOpeningClosing = skipIntroOutro.toString();
  }, [skipIntroOutro]);

  const [autoFullscreen, setAutoFullscreen] = React.useState(
    JSON.parse(localStorage.autoFullscreen || "false")
  );

  React.useEffect(() => {
    localStorage.autoFullscreen = autoFullscreen.toString();
  }, [autoFullscreen]);

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function logOut() {
    closeMenu();
    window.setUser(undefined);
    window.location.reload();
  }


  return (
    <React.Fragment>
      <MuiAppBar
        position="fixed"
        className={styles.appBar}
        style={{
          background:
            "radial-gradient(ellipse at bottom,rgba(121,22,221,.7),rgba(59,0,135,.7) 40%,#3b0087)",
        }}
      >
        <Toolbar className={styles.toolbar}>
          {/*<Hidden smUp implementation="js">
						<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={setDrawOpen(true)}>
							<MenuIcon />
						</IconButton>
					</Hidden>*/}
          {(!isMobile || !searching) && (
            <Typography
              variant="h4"
              style={{
                flexGrow: 1,
                fontWeight: 400,
                textTransform: "none",
                color: "white",
                marginInline: 4
              }}
            >
              <Link
                variant="inherit"
                color="inherit"
                component={RouterLink}
                // style={{ fontFamily: 'fieldwork, Arial' }}
                to={"/"}
              >
                Anime
              </Link>
            </Typography>
          )}

          {!isMobile || searching ? (
            <Search {...{ setSearching, isMobile }} />
          ) : (
            <div style={{ flexGrow: 1 }} />
          )}

          {(!isMobile || !searching) && (
            <div style={{ flexGrow: 0, display: "flex" }}>
              {isMobile && (
                <IconButton
                  onClick={() => {
                    setSearching(true);
                  }}
                >
                  <SearchIcon style={{marginInline: "8px"}} />
                </IconButton>
              )}
              <Avatar
                onLoad={(a) => (a.target.className = "animate")}
                alt={user.name}
                src={user.imageUrl || user.picture}
                onClick={openMenu}
                className={styles.avatar}
              />
              <Popover
                open={!!anchorEl}
                // id="frosted"
                anchorEl={anchorEl}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{
                  paper: styles.popover + " frosted",
                }}
                PaperProps={{ id: "frosted" }}
              >
                <div className={classes.container}>
                  <Avatar
                    alt={user.fullName}
                    className={classes.avatar}
                    src={user.imageUrl || user.picture}
                    onLoad={(a) => (a.target.className = "animate")}
                  ></Avatar>
                  <div className={classes.userInfo}>
                    <Typography
                      className={classes.userName}
                      variant="h6"
                      component="span"
                      color="textPrimary"
                    >
                      {user.fullName}
                    </Typography>
                    <Typography
                      className={classes.userEmail}
                      variant="body1"
                      component="span"
                      color="textSecondary"
                    >
                      {user.email}
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="caption"
                  style={{
                    float: "left",
                    color: "rgb(256, 256, 256, 0.5)",
                    marginLeft: "10px",
                    marginTop: "-8px",
                    marginBottom: "-4px",
                  }}
                >
                  Version:{" "}
                  {preval`module.exports = new Date().toLocaleString();`}
                </Typography>
                <Divider />
                <List style={{ padding: "0 0 0 0" }}>
                  <ListItem
                    button
                    onClick={() => {
                      setFeedbackModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText primary="Send feedback" />
                  </ListItem>
                  {window.updateAvailable && <ListItem
                    button
                    onClick={() => {
                      window.updateApp();
                    }}
                  >
                    <ListItemIcon>
                      <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Update" />
                  </ListItem>}
                  <ListItem
                    role={undefined}
                    dense
                    button
                    onClick={() => setSkipIntoOutro(!skipIntroOutro)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={skipIntroOutro}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Skip opening and closing credits" />
                  </ListItem>
                  <ListItem
                    role={undefined}
                    dense
                    button
                    onClick={() => setAutoFullscreen(!autoFullscreen)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={autoFullscreen}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Automatically fullscreen" />
                  </ListItem>
                </List>
                <Divider />
                <div className={classes.bar}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={logOut}
                    classes={{ root: classes.buttonSignout }}
                  >
                    Sign out
                  </Button>
                  {/* <Button variant="outlined" size="small" onClick={refreshPermissions} classes={{ root: classes.buttonPermissions }}>Refresh permissions</Button> */}
                </div>
              </Popover>
            </div>
          )}
        </Toolbar>
      </MuiAppBar>
      {feedbackModalOpen && (
        <Feedback setFeedbackModalOpen={setFeedbackModalOpen} />
      )}
    </React.Fragment>
  );
}

