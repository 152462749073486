import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";

export default function UpdateAlert() {
    console.log('rendering UpdateAlert')
    const [open, setOpen] = React.useState(false);
    const registration = React.useRef(null);

    function makeAlert(reg) {
        setOpen(true)
        window.updateAvailable = true
        registration.current = reg
    }

    window.makeUpdateAlert = makeAlert

    function updateApp() {
        registration.current.postMessage({ type: 'SKIP_WAITING' })
    }

    window.updateApp = updateApp


    return (<Snackbar
        open={open}
        autoHideDuration={20000}
        onClose={() => {
            setOpen(false);
        }}
    >
        <Alert
            severity={"info"}
            onClose={() => {
                setOpen(false);
            }}
            action={
                <Button color="inherit" size="small" onClick={updateApp} >
                    update now
                </Button>
            }
        >
            A new update is available
        </Alert>
    </Snackbar>)
}

