/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Button from "@material-ui/core/Button";
// import shaka from 'shaka-player/dist/shaka-player.ui';
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import NProgress from "nprogress";
import React, { Suspense } from "react";
import {
    Link as RouterLink
} from "react-router-dom";
import RecentShowCards from "./components/recent-cards";
import { ShowCards } from "../../components/show-cards";
import callAPI from "../../utils/api.js";

// import { FixedSizeList } from "react-window";
// import CircularProgress from '@material-ui/core/CircularProgress';
const Management = React.lazy(() => import("./management"));

const ShowCardsMemo = React.memo(ShowCards);

export default function Home(props) {
    const user = window.user;

    const [trendingShows, setTrendingShows] = React.useState(
        localStorage.trendingShows ? JSON.parse(localStorage.trendingShows) : []
    );
    const [renderShowCards, setRenderShowCards] = React.useState(false)

    setTimeout(() => setRenderShowCards(true), 1)

    console.log("rendering Home");

    const [recentShows] = React.useState(() => {
        var recentShows = [];

        if (localStorage.recentShows) {
            recentShows = JSON.parse(localStorage.recentShows);
            recentShows.length = 12;
        }

        return recentShows;
    });
    //const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = props.isMobile;

    document.title = "Anime";

    React.useEffect(() => {
        const now = new Date();
        if (
            !(localStorage.trendingShowsTime && localStorage.trendingShows) ||
            now.getTime() - localStorage.trendingShowsTime > 86400000
        ) {
            NProgress.start();
            callAPI("GetTrending", user.token, {}, function (err, res) {
                NProgress.done();
                if (!err) {
                    localStorage.trendingShows = JSON.stringify(res.list);
                    localStorage.trendingShowsTime = now.getTime();
                    setTrendingShows(res.list);
                }
            });
        }
    }, [user.token]);

    return (
        <Fade in>
            <div>
                <a
                    href="https://agar.benhong.me/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/img/agar.png"
                        alt="Ben Hong's private Agar.io server is at https://agar.benhong.me/"
                        onLoad={(a) => (a.target.className = "animate")}
                        href="https://agar.benhong.me/"
                        width={3000}
                        height={1000}
                        style={{
                            width: "100%",
                            height: isMobile ? "55vw" : "auto",
                            objectFit: "cover",
                        }}
                    />
                </a>
                <div
                    style={{
                        width: "92%",
                        height: "100%",
                        marginLeft: "4%",
                        marginRight: "4%",
                        zoom: isMobile ? 1 : 1,
                        // marginTop: '150px'
                        paddingBottom: '50px'
                    }}
                >
                    {recentShows.length !== 0 && localStorage.progress && (
                        <RecentShowCards />
                    )}

                    {renderShowCards ? (<Fade in>
                        <div>
                            {recentShows.length !== 0 && (
                                <div>
                                    <Typography
                                        style={{
                                            marginTop: "40px",
                                            display: "block",
                                            marginBottom: "15px",
                                            fontWeight: "600",
                                        }}
                                        variant="h4"
                                    >
                                        Recently viewed:
                                    </Typography>
                                    <ShowCardsMemo shows={recentShows} isMobile={isMobile} />
                                </div>
                            )}

                            <div style={{
                                marginBlock: "40px",
                                height: '10px'
                            }}>
                                <Typography
                                    style={{
                                        float: 'left',
                                        display: "block",
                                        fontWeight: "600",
                                    }}
                                    variant="h4"
                                >
                                    Popular:
                                </Typography>
                                <Button
                                    variant="outlined"
                                    component={RouterLink}
                                    style={{ float: "right", right: 8 }}
                                    onClick={() => window.scrollTo(0, 0)}
                                    to="/shows"
                                >
                                    view all
                                </Button>
                            </div>

                            <ShowCardsMemo shows={trendingShows} isMobile={isMobile} />

                            {user.email === "19458@wc.school.nz" && (
                                <Suspense fallback={<div height="1200px">Loading management...</div>}>
                                    <Management />
                                </Suspense>
                            )}
                        </div>
                    </Fade>)
                        : (
                            <div style={{ height: "1000px" }} />
                        )}
                </div>
            </div>
        </Fade>
    );
}