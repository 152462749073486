import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import ReactDOM from "react-dom";

export default function Alerts() {
    console.log('rendering Alerts')

    const [error, setError] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [duration, setDuration] = React.useState(false);

    function makeAlert(error, severity, duration) {
        ReactDOM.unstable_batchedUpdates(() => {
            setError(error)
            setSeverity(severity)
            setDuration(duration || 5000)
            setOpen(true)
        })
    }

    window.makeAlert = makeAlert

    return (<Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={() => {
            setOpen(false);
        }}
    >
        <Alert severity={severity} onClose={() => {
            setOpen(false);
        }}>
            {error}
        </Alert>
    </Snackbar>)
}