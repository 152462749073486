// import { Redirect, Link as RouterLink } from "react-router-dom";
// import updateUserAndDoTheLocalstorageThing, {
// 	userLocalStorageNamespace
// } from "../utils/userLocalStorage.js";

import AppBar from "@material-ui/core/AppBar";
// import AppContext from "../context";
import Container from "@material-ui/core/Container";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import NProgress from 'nprogress';
import React from "react";

const CLIENT_ID =
	"299968573660-khvhlujl0fb0j13mmip4los610lnqdet.apps.googleusercontent.com";

const useStyles = makeStyles(() => ({
	mainContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: "100px"
	},
	toolbarRight: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto"
	},
	dollarIcon: {
		height: "90%",
		width: "90%"
	},
	Anime: {
		color: '#FCC000'
	},
}));

export default function Login() {
	const classes = useStyles();

	document.title = "Anime";

	async function onGoogleSignIn(gResponse) {
		NProgress.start();
		const response = await fetch(`/TokenSignIn?token=${gResponse.credential}`, { method: "POST" })
		NProgress.done();
		if (response.status === 200) {
			const newUser = await response.json()
			if (newUser.result === "Failure") {
				window.makeAlert("Access denied. Ask Ben to add you to the whitelist.", "error")
				return
			}
			window.setUser(newUser)
			window.makeAlert("Login succeeded", "success")
		} else {
			window.makeAlert("Login error (1)", "error")
		}
	}

	window.onGoogleSignIn = onGoogleSignIn

	return (
		<React.Fragment>
			<AppBar position="fixed" style={{ background: 'radial-gradient(ellipse at bottom,rgba(121,22,221,.7),rgba(59,0,135,.7) 40%,#3b0087)' }}>
				<Toolbar>
					<Typography variant="h4" style={{
						fontWeight: 400,
						textTransform: "none",
						color: "white",
						margin: '15px'
					}}>
						Anime
					</Typography>
					<div className={classes.toolbarRight}>
						<div>
							<div id="g_id_onload"
								data-client_id={CLIENT_ID}
								data-ux_mode="popup"
								data-callback="onGoogleSignIn"
								data-auto_select="true"
								data-close_on_tap_outside="false">
							</div>

							<div className="g_id_signin"
								data-type="standard"
								data-shape="rectangular"
								data-theme="outline"
								data-text="signin_with"
								data-size="large"
								data-logo_alignment="left">
							</div>
						</div>
					</div>
				</Toolbar>
				<Divider />
			</AppBar>
			<Container className={classes.mainContent}>
				<Typography variant="h1" style={{ fontWeight: 500 }}>
					Poggers?
				</Typography>
				<Typography variant="h2">
					Sign in if I know you.
				</Typography>
			</Container>
		</React.Fragment>
	);
}
