/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Rating from "@material-ui/lab/Rating";
import { useLiveQuery } from "dexie-react-hooks";
import NProgress from "nprogress";
import React from "react";
import { Carousel } from "react-responsive-carousel";
// import Carousel from 'react-material-ui-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
  Route,
  Switch as RouterSwitch,
  Link,
  useHistory,
  useLocation, useRouteMatch
} from "react-router-dom";
import "../../css/fade.css";
import db from "../../db";
import callAPI from "../../utils/api.js";
import EpisodesViewer from "./episodes";
import Player from "./episode";
import genres from "../../data/genres.json";

const useStyles = makeStyles((theme) => ({
  classPage: {
    padding: theme.spacing(8),
  },
  storePage: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  classSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  classSectionItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  clasSectionPaper: {
    padding: theme.spacing(2),
    width: "95%",
  },
  classSettingInput: {
    minWidth: 160,
    margin: {
      top: theme.spacing(1),
      bottom: theme.spacing(1),
      right: theme.spacing(2),
    },
  },
  episodeCard: {
    // backgroundColor: 'primary',
    // color: 'primary',
    textAlign: "left",
  },
  track: {
    color: "white",
  },
  rail: {
    color: "grey",
  },
  thumb: {
    color: "white",
    backgroundColor: "white",
    boxShadow: "0 0 10px  rgba(0,0,0,0.6)",
  },
  player: {
    height: "100%",
    width: "100%",
  },
  label: {
    // fontFamily: 'fieldwork, sans-serif',
    marginBottom: "-2px",
    color: "rgba(255,255,255,.9)",
  },
  select: {
    marginTop: "-0.125rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
    },
  },
  bar: {
    backgroundColor: "#1a90ff",
  },
  // curtain: {
  //     zIndex: 2
  // },
}));


function useForceUpdate() {
  const [, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function ExpandableText({
  children,
  variant = "body1",
  style,
  offset
}) {
  const ref = React.createRef();
  const [showMore, setShowMore] = React.useState(false);
  const [showLink, setShowLink] = React.useState(false);

  const lines = Math.max(Math.ceil(window.innerWidth / 100 - 10 + offset), 3)

  React.useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  const onClickMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <Typography
        variant={variant}
        ref={ref}
        style={{
          lineHeight: "1.3em",
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitLineClamp: showMore ? 9999 : lines,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          ...style,
        }}
      >
        {children}
      </Typography>
      {showLink && (
        <span
          className="link more"
          onClick={onClickMore}
          style={{ cursor: "pointer" }}
        >
          {showMore ? "show less" : "show more"}
        </span>
      )}
    </>
  );
}

export default function ShowInfo(props) {
  console.log("rendering ShowInfo");
  const classes = useStyles();
  const user = window.user;
  const { path, } = useRouteMatch();
  const { showName } = props;
  // const [episodesLoaded, setEpisodesLoaded] = React.useState(false);
  const forceUpdate = useForceUpdate();
  const [episodesNumber, setEpisodesNumber] = React.useState(
    localStorage.getItem("episodesNumbers") &&
    (JSON.parse(localStorage.getItem("episodesNumbers"))[showName] || 0)
  );
  const episodes = useLiveQuery(async () => {
    var episodesObj = {};
    var episodeArr = await db.episodes.where("show").equals(showName).toArray();
    for (episode of episodeArr) {
      episodesObj[episode.sequence] = episode.data;
    }
    setEpisodesNumber(
      Math.max(
        localStorage.getItem("episodesNumbers")
          ? JSON.parse(localStorage.getItem("episodesNumbers"))[showName] || 0
          : 0,
        episodeArr.length
      )
    );
    return episodesObj;
  }, [showName]);

  // const episodeProgress = useLiveQuery(async () => {
  //     var episodesObj = {}
  //     var episodeArr = await db.episodeProgress.where('show').equals(showName).toArray()
  //     for (episode of episodeArr) {
  //         episodesObj[episode.sequence] = episode
  //     }
  //     return episodesObj
  // }, [showName]);

  const [episodeProgress, setEpisodeProgress] = React.useState({});

  console.logDebug("episodes", episodes);
  // const [episodeData, setEpisodeData] = React.useState();
  // const [episodesLoading, setEpisodesLoading] = React.useState([]);
  // const [indexesLoading, setIndexesLoading] = React.useState([]);
  const [redownloadNeeded, setRedownloadNeeded] = React.useState(false);
  const isMobile = window.isMobile;
  const setAppBarShown = window.setAppBarShown;
  const history = useHistory();

  const shows = window.shows;

  var recentShows = [];

  if (localStorage.recentShows) {
    recentShows = JSON.parse(localStorage.recentShows);
  }

  for (var i = 0; i < recentShows.length; i++) {
    if (recentShows[i] === showName) {
      recentShows.splice(i, 1);
    }
  }

  recentShows.unshift(showName);

  localStorage.recentShows = JSON.stringify(recentShows);

  var episode = useLocation()
    .pathname.replace("/shows/" + showName, "")
    .replace("/", "");

  var show = shows[showName];

  var progress = { episode: "1", time: 0 };

  var currentProgress = {};
  if (localStorage.progress) {
    currentProgress = JSON.parse(localStorage.progress);
  }

  if (currentProgress[showName]) {
    progress = currentProgress[showName];
  }

  window.progress = progress;

  window.episodesNumber = episodesNumber;

  const [episodeIndex, setEpisodeIndex] = React.useState(
    progress.data
      ? Math.floor((parseInt(progress.episode) - 1) / (isMobile ? 2 : 6))
      : 0
  );

  console.logDebug("episode index", episodeIndex);

  var seasonStartingEpisodes = {};
  var totalEpisodeCount = 1;
  var progressSeason = Object.keys(show.seasons)[0];
  for (i of Object.keys(show.seasons)) {
    seasonStartingEpisodes[i] = totalEpisodeCount;
    if (parseInt(progress.episode) > totalEpisodeCount) {
      progressSeason = i;
    }
    totalEpisodeCount += show.seasons[i];
  }

  const [season, setSeason] = React.useState(progressSeason);

  window.episodes = episodes;

  function downloadEpisodes(index) {
    console.logDebug("episode download", index);
    if (episodes && !window.indexesLoading.includes(index)) {
      window.indexesLoading.push(index);
      NProgress.start();
      callAPI(
        show.isMovie ? "GetMovie" : "GetEpisodes",
        user.token,
        { showId: parseInt(show.id), index: parseInt(index) },
        function (err, res) {
          if (res.noChange) {
            console.logDebug("new download, not stopping load");
          } else {
            NProgress.done();
          }
          if (!err) {
            db.transaction("rw", db.episodes, () => {
              var newEpisode;
              for (newEpisode of res.list) {
                db.episodes.put({
                  show: newEpisode.showSlug,
                  sequence: newEpisode.sequence,
                  data: newEpisode,
                });
              }
            });
            if (res.episodeNum && res.episodeNum > episodesNumber) {
              setEpisodesNumber(res.episodeNum);
            }
            if (res.firstDownload) {
              dataHasBeenUpdated();
            }
            if (res.noChange) {
              for (var i = 0; i < window.indexesLoading.length; i++) {
                if (window.indexesLoading[i] === index) {
                  window.indexesLoading.splice(i, 1);
                }
              }
            }
            if (res.releaseTimes) {
              localStorage.simulcastReleaseTimes = JSON.stringify({
                ...(localStorage.simulcastReleaseTimes &&
                  JSON.parse(localStorage.simulcastReleaseTimes)),
                showName: {
                  day: res.releaseWeekday,
                  upper: res.releaseUpperTime,
                  lower: res.releaseLowerTime,
                },
              });
            }
          }
        }
      );
    }
  }

  function downloadMissingEpisodes() {
    console.logDebug("getting missing episodes");
    NProgress.start();

    callAPI(
      "GetMissing",
      user.token,
      { showId: parseInt(show.id), existing: Object.keys(episodes) },
      function (err, res) {
        NProgress.done();
        if (!err) {
          db.transaction("rw", db.episodes, () => {
            var newEpisode;
            for (newEpisode of res.list) {
              db.episodes.put({
                show: newEpisode.showSlug,
                sequence: newEpisode.sequence,
                data: newEpisode,
              });
            }
          });
          if (res.episodeNum && res.episodeNum > episodesNumber) {
            setEpisodesNumber(res.episodeNum);
          }
        }
      }
    );
  }

  function getSimulcastReleaseTimes() {
    if (Date.now() < show.simulcastEndDate) {
      callAPI(
        "GetReleaseTimes",
        user.token,
        { showId: parseInt(show.id) },
        function (err, res) {
          NProgress.done();
          if (!err && res.releaseTimes) {
            localStorage.simulcastReleaseTimes = JSON.stringify({
              ...(localStorage.simulcastReleaseTimes &&
                JSON.parse(localStorage.simulcastReleaseTimes)),
              [showName]: {
                day: res.releaseWeekday,
                upper: res.releaseUpperTime,
                lower: res.releaseLowerTime,
              },
            });
            forceUpdate();
          }
        }
      );
    }
  }

  function dataHasBeenUpdated() {
    if (episodes) {
      if (episodeIndex != null) {
        const simulcasting =
          Object.keys(episodes).length !== 0 &&
          episodes[Math.max(...Object.keys(episodes))].updateDate <
          show.simulcastEndDate - 48 * 60 * 60 * 1000 &&
          episodes[Math.max(...Object.keys(episodes))].updateDate +
          6 * 24 * 60 * 60 * 1000 <
          Date.now();
        if (
          ![...Array(6).keys()].some((i) =>
            Object.keys(episodes).includes(
              (parseInt(episodeIndex) * 6 + i).toString()
            )
          ) ||
          (Math.floor(parseInt(episodesNumber) / 6) === episodeIndex &&
            simulcasting)
        ) {
          downloadEpisodes(episodeIndex);
        }
        if (episodeIndex !== Math.floor(episodesNumber / 6)) {
          if (
            ![...Array(6).keys()].some((i) =>
              Object.keys(episodes).includes(
                (parseInt(episodeIndex + 1) * 6 + i).toString()
              )
            ) ||
            (Math.floor(parseInt(episodesNumber) / 6) === episodeIndex + 1 &&
              simulcasting)
          ) {
            downloadEpisodes(episodeIndex + 1);
          }
        }
        if (episodeIndex !== 0) {
          if (
            ![...Array(6).keys()].some((i) =>
              Object.keys(episodes).includes(
                (parseInt(episodeIndex - 1) * 6 + i).toString()
              )
            )
          ) {
            downloadEpisodes(episodeIndex - 1);
          }
        }
      }
    }
  }

  React.useEffect(() => {
    if (episodes && Object.keys(episodes).length !== 0 && !show.isMovie) {
      // console.log(episodes)
      const simulcasting =
        episodes[Math.max(...Object.keys(episodes))].updateDate <
        show.simulcastEndDate - 48 * 60 * 60 * 1000 &&
        episodes[Math.max(...Object.keys(episodes))].updateDate +
        6 * 24 * 60 * 60 * 1000 <
        Date.now();
      if (simulcasting && !episodes[progress.episode]) {
        downloadEpisodes(Math.floor(parseInt(progress.episode) / 6));
      }
    }
  }, [episodes]);

  if (redownloadNeeded) {
    setRedownloadNeeded(false);
    dataHasBeenUpdated();
  }

  React.useEffect(() => {
    if (episodesNumber != 0) {
      if (localStorage.episodesNumbers) {
        localStorage.episodesNumbers = JSON.stringify({
          ...JSON.parse(localStorage.episodesNumbers),
          [showName]: episodesNumber,
        });
      } else {
        localStorage.episodesNumbers = JSON.stringify({
          [showName]: episodesNumber,
        });
      }
    }
  }, [episodesNumber]);

  async function updateProgress() {
    var episodesObj = {};
    var episodeArr = await db.episodeProgress
      .where("show")
      .equals(showName)
      .toArray();
    for (episode of episodeArr) {
      episodesObj[episode.sequence] = episode;
    }
    setEpisodeProgress(episodesObj);
  }

  React.useEffect(() => {
    window.indexesLoading = [];
    var progress = { episode: "1", time: 0 };

    var currentProgress = {};

    if (localStorage.progress) {
      currentProgress = JSON.parse(localStorage.progress);
    }

    if (currentProgress[showName]) {
      progress = currentProgress[showName];
    }

    setEpisodeIndex(
      Math.floor((parseInt(progress.episode) - 1) / (isMobile ? 2 : 6))
    );
    // db.episodes.put({ show: "test", sequence: 9999999, data: Math.random() });
    // setEpisodesNumber(storedEpisodeNumbers[showName] ? storedEpisodeNumbers[showName] : 0);
    setRedownloadNeeded(true);
    window.scrollTo(0, 0);

    updateProgress();

    setSeason(progressSeason)

    if (
      !(
        localStorage.simulcastReleaseTimes &&
        JSON.parse(localStorage.simulcastReleaseTimes)[showName]
      )
    ) {
      getSimulcastReleaseTimes();
    }
  }, [showName]);

  React.useEffect(() => {
    console.logDebug("updating data");
    dataHasBeenUpdated();
  }, [episodeIndex, episodes]);

  React.useEffect(() => {
    console.logDebug("episode change", episode);
    if (episode) {
      if (episodes) {
        if (!Object.keys(episodes).includes(episode)) {
          downloadEpisodes(Math.floor((parseInt(episode) - 1) / 6));
        }
        if (
          parseInt(episode) + 6 <= episodesNumber &&
          !Object.keys(episodes).includes((parseInt(episode) + 6).toString)
        ) {
          downloadEpisodes(Math.floor((parseInt(episode) + 6 - 1) / 6));
        }
      }
    }
  }, [episode]);

  React.useEffect(() => {
    if (episodes) {
      if (parseInt(progress.episode) > 12) {
        if (
          !Object.keys(episodes).includes(parseInt(progress.episode).toString())
        ) {
          downloadEpisodes(Math.floor((parseInt(progress.episode) - 1) / 6));
        }
      }
    }
  }, []);

  document.title = show.name;

  console.logDebug("episode number", episodesNumber);

  var days = [
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];

  // var months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  var simulcastReleaseTimes =
    localStorage.simulcastReleaseTimes &&
    JSON.parse(localStorage.simulcastReleaseTimes)[showName];

  function showTwoDigits(number) {
    return ("00" + number).slice(-2);
  }

  async function goToEpisode(episode) {
    history.push("/shows/" + showName + "/" + episode.toString());
    if (window.outerHeight > window.outerWidth || JSON.parse(localStorage.autoFullscreen || "false")) {
      await document.documentElement.requestFullscreen();
      if (window.screen.orientation) {
        try {
          // Locking to 'landscape' should let it be either
          // 'landscape-primary' or 'landscape-secondary' as appropriate.
          await window.screen.orientation.lock("landscape");
          await window.screen.lockOrientation("landscape");
        } catch (error) {/* continue regardless of error */ }
      }
    }
  }

  window.goToEpisode = goToEpisode

  return (
    <RouterSwitch>
      <Route exact path={path}>
        <Fade in>
          <div style={{ width: "100%", zoom: props.zoomedOut ? 0.8 : 1 }}>
            <div id="transluscent">
              <img
                onLoad={(a) => (a.target.className = "animate")}
                src={
                  "/img/anime/" +
                  (isMobile ? show.wide || show.stage : show.stage || show.wide)
                }
                width={isMobile ? 1920 : show.stage ? 1200 : 1920}
                height={isMobile ? 1080 : show.stage ? 400 : 1080}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: isMobile ? "" : "33vw",
                  objectFit: "cover",
                  marginBottom: "-6.5px",
                  zIndex: -2,
                }}
              />
            </div>
            <Grid
              container
              style={{
                paddingLeft: isMobile ? "20px" : "80px",
                width: "100%",
                marginTop: "0px",
              }}
              spacing={6}
            >
              {!isMobile && (
                <Grid item xs={4}>
                  <div style={{ position: "relative" }}>
                    <img
                      onLoad={(a) => (a.target.className = "animate")}
                      src={"/img/anime/" + show.portrait}
                      height={1200}
                      width={800}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "auto",
                        // boxShadow: "11px -12px 37px 0 rgba(0,0,0,.3)",
                        borderRadius: '4px',
                        marginTop: show.stage || show.wide ? "-100px" : "-50px",
                        zIndex: 1
                      }}
                    />
                    <div
                      id="transluscent"
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingBottom: "150%",
                        boxShadow: "11px -12px 37px 0 rgba(0,0,0,.3)",
                        borderRadius: '4px',
                        top: show.stage || show.wide ? "-100px" : "-50px",
                        marginBottom: "-50px",
                        zIndex: 0
                      }}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={isMobile ? 12 : 8}>
                <div
                  style={{
                    marginBottom: "20px",
                    marginTop: isMobile ? "0px" : "10px",
                  }}
                >
                  <Typography
                    variant={show.name.length > 25 ? "h3" : "h2"}
                    style={{ fontWeight: 600 }}
                  >
                    {show.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontStyle: "italic", marginTop: "-5px" }}
                  >
                    {" "}
                    {show.original}
                  </Typography>
                  <Rating
                    name="read-only"
                    style={{ marginTop: "5px", zoom: isMobile ? 0.8 : 1 }}
                    value={(show.rating / 100) * 5}
                    readOnly
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: "0px", color: "rgba(255,255,255,.8)" }}
                  >
                    {[show.year, show.ratingCode, show.languages.join(" and ")]
                      .filter((a) => a)
                      .join(" • ")}
                  </Typography>
                </div>
                {progress.data ? (
                  show.isMovie ? (
                    <Button
                      startIcon={<PlayArrowIcon />}
                      size="large"
                      variant="contained"
                      onClick={() => goToEpisode(episodes[1] ? 1 : 0)}
                      color="secondary"
                    >
                      {progress.time > 10
                        ? "Continue watching"
                        : "Start watching"}
                    </Button>
                  ) : (
                    <Button
                      startIcon={<PlayArrowIcon />}
                      size="large"
                      variant="contained"
                      onClick={() => goToEpisode(progress.episode)}
                      color="secondary"
                    >
                      {progress.time > 10 ? "Continue watching" : "Watch"}{" "}
                      {progress.data.season.name} Episode{" "}
                      {progress.data.episodeNumber}
                    </Button>
                  )
                ) : !episodes || Object.keys(episodes).length === 0 ? (
                  <Button
                    startIcon={<PlayArrowIcon />}
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled
                  >
                    Loading...
                  </Button>
                ) : show.isMovie ? (
                  <Button
                    startIcon={<PlayArrowIcon />}
                    size="large"
                    variant="contained"
                    onClick={() => goToEpisode(episodes[1] ? 1 : 0)}
                    color="secondary"
                  >
                    {progress.time > 10
                      ? "Continue watching"
                      : "Start watching"}
                  </Button>
                ) : episodes[progress.episode] ? (
                  <Button
                    startIcon={<PlayArrowIcon />}
                    size="large"
                    variant="contained"
                    onClick={() => goToEpisode(progress.episode)}
                    color="secondary"
                  >
                    {progress.time > 10 ? "Continue watching" : "Watch"}{" "}
                    {episodes[progress.episode].season &&
                      episodes[progress.episode].season.name}{" "}
                    Episode {episodes[progress.episode].episodeNumber}
                  </Button>
                ) : (
                  <Button
                    startIcon={<PlayArrowIcon />}
                    size="large"
                    variant="contained"
                    onClick={() =>
                      goToEpisode(
                        episodes[Math.min(...Object.keys(episodes))].sequence
                      )
                    }
                    color="secondary"
                  >
                    Watch{" "}
                    {episodes[Math.min(...Object.keys(episodes))].season &&
                      episodes[Math.min(...Object.keys(episodes))].season
                        .name}{" "}
                    Episode{" "}
                    {episodes[Math.min(...Object.keys(episodes))].episodeNumber}
                  </Button>
                )}
                <Typography variant="h5" style={{ marginTop: "25px" }}>
                  {show.tagline}
                </Typography>
                <ExpandableText
                  variant="body"
                  style={{ color: "rgba(255,255,255,.8)" }}
                  offset={show.original ? 0 : 1}
                >
                  {show.longSynopsis}
                </ExpandableText>
                {Date.now() < show.simulcastEndDate && (
                  <div style={{ marginTop: "15px" }}>
                    <Typography variant="h6">
                      {
                        Object.keys(show.seasons)[
                        Object.keys(show.seasons).length - 1
                        ]
                      }
                      :
                    </Typography>
                    <div
                      style={{
                        paddingLeft: "7px",
                        marginTop: "-10px",
                        color: "rgba(255,255,255,.9)",
                      }}
                    >
                      <div>
                        <DateRangeIcon style={{ float: "left" }} />
                        <div>
                          <Typography
                            variant="h6"
                            style={{ marginTop: "4px", marginLeft: "35px" }}
                          >
                            Simulcasting from{" "}
                            {new Date(
                              show.simulcastStartDate - 2 * 60 * 60 * 1000
                            ).toDateString() +
                              " - " +
                              new Date(
                                show.simulcastEndDate - 2 * 60 * 60 * 1000
                              ).toDateString()}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ marginTop: "-7px" }}>
                        <HourglassEmptyIcon style={{ float: "left" }} />
                        <Typography
                          variant="h6"
                          style={{ marginTop: "4px", marginLeft: "35px" }}
                        >
                          {Date.now() < show.simulcastStartDate
                            ? "Simulcast starts in "
                            : "Simulcast ends in "}
                          {Math.floor(
                            (Date.now() < show.simulcastStartDate
                              ? show.simulcastStartDate - Date.now()
                              : show.simulcastEndDate - Date.now()) /
                            1000 /
                            60 /
                            60 /
                            24 /
                            7
                          )}{" "}
                          weeks and{" "}
                          {Math.ceil(
                            (Date.now() < show.simulcastStartDate
                              ? show.simulcastStartDate - Date.now()
                              : show.simulcastEndDate - Date.now()) /
                            1000 /
                            60 /
                            60 /
                            24
                          ) -
                            Math.floor(
                              (Date.now() < show.simulcastStartDate
                                ? show.simulcastStartDate - Date.now()
                                : show.simulcastEndDate - Date.now()) /
                              1000 /
                              60 /
                              60 /
                              24 /
                              7
                            ) *
                            7}{" "}
                          days
                        </Typography>
                      </div>
                      {simulcastReleaseTimes && (
                        <div style={{ marginTop: "-7px" }}>
                          <ScheduleIcon style={{ float: "left" }} />
                          <Typography
                            variant="h6"
                            style={{ marginTop: "4px", marginLeft: "35px" }}
                          >
                            New episodes on {days[simulcastReleaseTimes.day]}{" "}
                            between{" "}
                            {showTwoDigits(simulcastReleaseTimes.lower.hour)}:
                            {showTwoDigits(simulcastReleaseTimes.lower.minute)}{" "}
                            and{" "}
                            {showTwoDigits(simulcastReleaseTimes.upper.hour)}:
                            {showTwoDigits(simulcastReleaseTimes.upper.minute)}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div style={{ marginTop: "20px" }}>
                  {show.genres.map((genre) => (
                    <Chip
                      classes={{ label: classes.label }}
                      component={Link}
                      clickable
                      to={`/genres/${genre.toLowerCase().replaceAll(' ', '-')}`}
                      size="small"
                      variant="outlined"
                      label={genres[genre]?.name || genre}
                      style={{
                        fontSize: "15px",
                        height: "22px",
                        marginRight: "5px",
                        color: "rgba(255,255,255,.9)",
                      }}
                    />
                  ))}
                </div>
                <Typography
                  variant="subtitle2"
                  style={{ marginTop: "25px", color: "rgba(255,255,255,.5)" }}
                >
                  {show.copyright}
                </Typography>
              </Grid>
            </Grid>
            {episodes && !(show.isMovie && Object.keys(episodes).length <= 1) ? (
              <div
                style={{
                  marginLeft: "5%",
                  minHeight: '330px',
                  marginRight: "5%",
                  marginTop: "10px",
                  width: "90%",
                }}
              >
                <Select
                  value={season}
                  onChange={(event) => {
                    setSeason(event.target.value);
                    setEpisodeIndex(
                      Math.floor(
                        (seasonStartingEpisodes[event.target.value] - 1) /
                        (isMobile ? 2 : 6)
                      )
                    );
                    console.logDebug(
                      "season change, set new episode to",
                      seasonStartingEpisodes[season],
                      "index to",
                      Math.floor(seasonStartingEpisodes[season] - 1 / 6)
                    );
                  }}
                  displayEmpty
                  style={{ margin: "20px" }}
                >
                  {Object.keys(show.seasons).map((season) => (
                    <MenuItem value={season}>{season}</MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  style={{ float: "right", margin: "20px" }}
                  onClick={downloadMissingEpisodes}
                >
                  redownload episodes
                </Button>
                <Carousel
                  interval={999999}
                  onChange={(index) => {
                    console.logDebug(
                      "change",
                      index,
                      Math.floor(index / (isMobile ? 3 : 1))
                    );
                    setEpisodeIndex(Math.floor(index / (isMobile ? 3 : 1)));
                  }}
                  showThumbs={false}
                  selectedItem={isMobile ? episodeIndex * 3 : episodeIndex}
                  style={{ width: "100%", paddingBottom: "50px" }}
                >
                  {[
                    ...Array(
                      Math.ceil(
                        (Math.max(
                          episodesNumber,
                          Math.max(...Object.keys(episodes))
                        ) +
                          1) /
                        (isMobile ? 2 : 6)
                      )
                    ).keys(),
                  ].map((i) => (
                    <EpisodesViewer
                      episodeProgress={episodeProgress}
                      numberOfCards={isMobile ? 2 : 6}
                      episodes={episodes}
                      key={i}
                      i={i}
                      index={episodeIndex}
                      show={show.id}
                      goToEpisode={goToEpisode}
                      isMobile={isMobile}
                    />
                  ))}
                </Carousel>
              </div>
            ) : <div style={{ height: '330px' }} />}
          </div>
        </Fade>
      </Route>
      <Route path={path + "/:episode"}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            zIndex: 10,
            backgroundColor: "black",
            position: "fixed",
          }}
        />
        {(episodes && Object.keys(episodes).includes(episode)) ||
          (progress.data && progress.data.sequence === parseInt(episode)) ? (
          <Player
            updateProgress={updateProgress}
            episodes={episodes}
            show={show}
            episode={episode}
            episodesNumber={episodesNumber}
            setAppBarShown={setAppBarShown}
          />
        ) : null}
      </Route>
    </RouterSwitch>
  );
}
