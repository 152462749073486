/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import {
    Link as RouterLink, useHistory
} from "react-router-dom";
import db from "../../../db";
import callAPI from "../../../utils/api.js";

function LoadingRecentShowCard(props) {
    const [checked, setChecked] = React.useState(false);
    const { show, progress, updateRecentShows } = props;
    const history = useHistory();
    const showData = window.shows[show];

    React.useEffect(() => {
        async function getNewEpisode() {
            var episodes = {};

            var episodeArr = await db.episodes.where("show").equals(show).toArray();
            for (var episode of episodeArr) {
                episodes[episode.sequence] = episode.data;
            }

            const cachedEpisode = episodes[progress.episode];

            if (cachedEpisode) {
                localStorage.progress = JSON.stringify({
                    ...JSON.parse(localStorage.progress),
                    [show]: { ...progress, data: cachedEpisode, waiting: false },
                });
                updateRecentShows();
                setChecked(true);
            } else {
                const latestEpisode = episodes[Math.max(...Object.keys(episodes))];
                const simulcasting =
                    latestEpisode.updateDate <
                    latestEpisode.season.simulcastEndDate - 48 * 60 * 60 * 1000 &&
                    latestEpisode.updateDate + 6 * 24 * 60 * 60 * 1000 < Date.now();

                if (simulcasting) {
                    const index = Math.floor(parseInt(progress.episode) / 6);
                    callAPI(
                        show.isMovie ? "GetMovie" : "GetEpisodes",
                        window.user.token,
                        { showId: parseInt(showData.id), index: parseInt(index) },
                        async function (err, res) {
                            if (!err) {
                                db.transaction("rw", db.episodes, () => {
                                    for (var newEpisode of res.list) {
                                        db.episodes.put({
                                            show: newEpisode.showSlug,
                                            sequence: newEpisode.sequence,
                                            data: newEpisode,
                                        });
                                    }
                                });

                                for (var newEpisode of res.list) {
                                    if (newEpisode.sequence >= progress.episode) {
                                        localStorage.progress = JSON.stringify({
                                            ...JSON.parse(localStorage.progress),
                                            [show]: { ...progress, data: newEpisode, waiting: false },
                                        });
                                        updateRecentShows();
                                        break;
                                    }
                                }

                                setChecked(true);

                                if (
                                    res.episodeNum &&
                                    res.episodeNum >
                                    JSON.parse(localStorage.episodesNumbers)[show]
                                ) {
                                    if (res.episodeNum != 0) {
                                        if (localStorage.episodesNumbers) {
                                            localStorage.episodesNumbers = JSON.stringify({
                                                ...JSON.parse(localStorage.episodesNumbers),
                                                [show]: res.episodeNum,
                                            });
                                        } else {
                                            localStorage.episodesNumbers = JSON.stringify({
                                                [show]: res.episodeNum,
                                            });
                                        }
                                    }
                                }

                                if (res.releaseTimes) {
                                    localStorage.simulcastReleaseTimes = JSON.stringify({
                                        ...(localStorage.simulcastReleaseTimes &&
                                            JSON.parse(localStorage.simulcastReleaseTimes)),
                                        showName: {
                                            day: res.releaseWeekday,
                                            upper: res.releaseUpperTime,
                                            lower: res.releaseLowerTime,
                                        },
                                    });
                                }
                            }
                        }
                    );
                } else {
                    setChecked(true);
                }
            }
        }

        getNewEpisode();
    }, []);

    function showTwoDigits(number) {
        return ("00" + number).slice(-2);
    }

    const days = [
        "Mondays",
        "Tuesdays",
        "Wednesdays",
        "Thursdays",
        "Fridays",
        "Saturdays",
        "Sundays",
    ];
    const simulcastReleaseTimes =
        localStorage.simulcastReleaseTimes &&
        JSON.parse(localStorage.simulcastReleaseTimes)[show];

    if (!showData) {
        return null;
    }

    return (
        <Grid item xs={12} sm={6} lg={4}>
            <Paper id="transluscent" style={{ width: "100%", overflow: "hidden" }}>
                <div
                    style={{
                        paddingBottom: "calc(100% * 9 / 22)",
                        width: "100%",
                        height: "auto",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            zIndex: "0.4",
                            left: "0",
                            top: "0",
                            position: "absolute",
                        }}
                    >
                        <img
                            onLoad={(a) => (a.target.className = "animate")}
                            src={"/img/anime/" + showData.smallPortrait}
                            width={400}
                            height={600}
                            style={{ width: "calc(100% * 3 / 11)", height: "auto" }}
                            alt={""}
                            onClick={() => history.push("/shows/" + showData.slug)}
                        />
                    </div>
                    <div
                        style={{
                            width: "calc(100% * 8 / 11)",
                            position: "absolute",
                            zIndex: "0.5",
                            right: "0",
                            top: "0",
                            padding: "16px",
                        }}
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            style={{ color: "rgba(255,255,255,1)" }}
                        >
                            <Link
                                variant="inherit"
                                color="inherit"
                                component={RouterLink}
                                to={"/shows/" + showData.slug}
                            >
                                {showData.name}
                            </Link>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ color: "rgba(255,255,255,0.9)" }}
                        >
                            {checked ? "No new episodes :(" : "Checking for new episodes..."}
                        </Typography>

                        {simulcastReleaseTimes && checked && (
                            <Typography
                                variant="body1"
                                style={{ marginTop: "10px", color: "rgba(255,255,255,0.9)" }}
                            >
                                New episodes on {days[simulcastReleaseTimes.day]} between{" "}
                                {showTwoDigits(simulcastReleaseTimes.lower.hour)}:
                                {showTwoDigits(simulcastReleaseTimes.lower.minute)} and{" "}
                                {showTwoDigits(simulcastReleaseTimes.upper.hour)}:
                                {showTwoDigits(simulcastReleaseTimes.upper.minute)}
                            </Typography>
                        )}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "16px",
                            }}
                        >
                            {!checked && (
                                <CircularProgress
                                    color="secondary"
                                    style={{
                                        // marginTop: '40px',
                                        // color: "white",
                                        margin: "auto",
                                        height: 38,
                                        width: 38,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Paper>
        </Grid>
    );
}

const WhiteLinearProgress = withStyles(() => ({
    bar: {
        backgroundColor: "#FFFFFF",
    },
}))(LinearProgress);


function RecentEpisodeCard(props) {
    console.log("render RecentEpisodeCard");

    const history = useHistory();
    const { episode, progress, previousEpisode, setEpisode, nextEpisode } = props;

    const showData = window.shows[episode.showSlug];

    async function goToEpisode() {
        history.push(
            "/shows/" + episode.showSlug + "/" + episode.sequence.toString()
        );
        if (window.outerHeight > window.outerWidth || JSON.parse(localStorage.autoFullscreen || "false")) {
            await document.documentElement.requestFullscreen();
            if (window.screen.orientation) {
                try {
                    // Locking to 'landscape' should let it be either
                    // 'landscape-primary' or 'landscape-secondary' as appropriate.
                    await window.screen.orientation.lock("landscape");
                    await window.screen.lockOrientation("landscape");
                } catch (error) {/* continue regardless of error */ }
            }
        }
    }

    var episodeImage = "";
    try {
        episodeImage =
            "https://proxy.benhong.me/corsproxy/?apiurl=" +
            []
                .concat(...episode.images.map((a) => a.imageInstances))
                .sort((a, b) => a.imageInfo.height - b.imageInfo.height)[0].imageInfo
                .fullPath;
    } catch (err) {
        console.logDebug("no episode image");
    }

    if (!showData) {
        return null;
    }

    return (
        <Grid item xs={12} sm={6} lg={4}>
            <Paper id="transluscent" style={{ width: "100%", overflow: "hidden" }}>
                <div
                    style={{
                        paddingBottom: "calc(100% * 9 / 22)",
                        width: "100%",
                        height: "auto",
                        position: "relative",
                        backgroundColor: "rgba(59, 0, 135, .3)",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            zIndex: "0.4",
                            left: "0",
                            top: "0",
                            position: "absolute",
                        }}
                    >
                        <img
                            onLoad={(a) => (a.target.className = "animate")}
                            src={"/img/anime/" + showData.smallPortrait}
                            width={400}
                            height={600}
                            style={{ width: "calc(100% * 3 / 11)", height: "auto" }}
                            alt={""}
                            onClick={() => history.push("/shows/" + episode.showSlug)}
                        />
                        <img
                            onLoad={(a) => (a.target.className = "animatetopointthree")}
                            src={episodeImage}
                            width={512}
                            height={288}
                            style={{ width: "calc(100% * 8 / 11)", height: "auto" }}
                            alt={""}
                            crossOrigin="anonymous"
                        />
                    </div>
                    <div
                        style={{
                            width: "calc(100% * 8 / 11)",
                            display: "flex",
                            flexFlow: "column",
                            height: "100%",
                            position: "absolute",
                            zIndex: "0.5",
                            right: "0",
                            top: "0",
                            padding: "16px",
                        }}
                    >
                        <div style={{ flex: "0 1 auto" }}>
                            <Typography variant="h5" noWrap>
                                <Link
                                    variant="inherit"
                                    color="inherit"
                                    component={RouterLink}
                                    to={"/shows/" + episode.showSlug}
                                >
                                    {episode.showTitle}
                                </Link>
                            </Typography>
                            {
                                <Typography
                                    variant="body1"
                                    noWrap
                                    style={{ color: "rgba(255,255,255,0.9)" }}
                                >
                                    {showData.isMovie
                                        ? Math.round((progress.duration - progress.time) / 60) +
                                        " minutes remaining"
                                        : episode.shortCode + " - " + episode.name}
                                </Typography>
                            }
                        </div>
                        <div style={{ margin: "auto" }}>
                            <div style={{ margin: "-20px" }}>
                                <IconButton
                                    aria-label="previous"
                                    disabled={!previousEpisode}
                                    onClick={() => setEpisode(previousEpisode)}
                                >
                                    <SkipPreviousIcon />
                                </IconButton>
                                <IconButton aria-label="play/pause" onClick={goToEpisode}>
                                    <PlayArrowIcon style={{ height: 38, width: 38 }} />
                                </IconButton>
                                <IconButton
                                    aria-label="next"
                                    disabled={!nextEpisode}
                                    onClick={() => setEpisode(nextEpisode)}
                                >
                                    <SkipNextIcon />
                                </IconButton>
                            </div>
                        </div>
                        <WhiteLinearProgress
                            variant="determinate"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                marginLeft: "-16px",
                            }}
                            value={progress ? (100 * progress.time) / progress.duration : 0}
                        />
                    </div>
                </div>
            </Paper>
        </Grid>
    );
}

const RecentEpisodeCardMemo = React.memo(RecentEpisodeCard);

function RecentShowCard(props) {
    console.log("render RecentShowCard");

    const { show } = props;
    const storedProgress = props.progress;
    const [episode, setEpisode] = React.useState(storedProgress.data);

    const dbProgress = useLiveQuery(
        () => db.episodeProgress.get([show, episode.sequence]),
        [episode.sequence]
    );

    const progress = dbProgress || (storedProgress.episode === episode.sequence && storedProgress);

    const episodes = useLiveQuery(async () => {
        var episodesObj = {};
        var episodeArr = await db.episodes
            .where("show")
            .equals(show)
            .and(
                (a) =>
                    a.sequence >= parseInt(episode.sequence) - 6 &&
                    a.sequence <= parseInt(episode.sequence) + 6
            )
            .toArray();
        for (var newEpisode of episodeArr) {
            episodesObj[newEpisode.sequence] = newEpisode.data;
        }
        return episodesObj;
    }, [episode.sequence]);

    var nextEpisode;
    var previousEpisode;

    if (episodes) {
        // if (Object.keys(episodes).length !== 0) console.log('previous and next six episode', episodes)
        var i;

        for (i of [...Array(6)].keys()) {
            if (episodes[parseInt(episode.sequence) + 1 + i]) {
                nextEpisode = episodes[parseInt(episode.sequence) + 1 + i];
                break;
            }
        }

        for (i of [...Array(6)].keys()) {
            if (episodes[parseInt(episode.sequence) - 1 - i]) {
                previousEpisode = episodes[parseInt(episode.sequence) - 1 - i];
                break;
            }
        }
    }

    return (
        <RecentEpisodeCardMemo
            episode={episode}
            progress={progress}
            previousEpisode={previousEpisode}
            nextEpisode={nextEpisode}
            setEpisode={setEpisode}
        />
    );
}

export default function RecentShowCards() {
    const [recentShows, setRecentShows] = React.useState(getRecentShows);
    const [loading, setLoading] = React.useState(false);

    function getRecentShows() {
        var progress = Object.entries(JSON.parse(localStorage.progress)).filter(
            (a) => !a[1].finished
        );
        progress.sort((a, b) => b[1].date - a[1].date);
        progress.length = 6;
        return progress;
    }

    function updateRecentShows() {
        setRecentShows(getRecentShows());
    }

    return (
        recentShows.some((a) => a) && (
            <div>
                <Typography
                    onClick={() => setLoading(!loading)}
                    style={{
                        marginTop: "30px",
                        display: "block",
                        marginBottom: "15px",
                        fontWeight: "600",
                    }}
                    variant="h4"
                >
                    Keep watching:
                </Typography>
                {/* <Slider
				value={value}
				onChange={handleChange}
				aria-labelledby="discrete-slider-small-steps"
				step={1}
				marks
				min={0}
				max={20}
				valueLabelDisplay="auto"
			/> */}

                <Grid container spacing={2}>
                    {recentShows.map((show) =>
                        show[1].data ? (
                            <RecentShowCard show={show[0]} progress={show[1]} />
                        ) : (
                            <LoadingRecentShowCard
                                show={show[0]}
                                progress={show[1]}
                                updateRecentShows={updateRecentShows}
                            />
                        )
                    )}
                </Grid>
            </div>
        )
    );
}