/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React from "react";
import {
    Route,
    Switch as RouterSwitch, useParams, useRouteMatch
} from "react-router-dom";
import WindowedShowCards from "../../components/windowed-show-cards.js";
import "../../css/fade.css";
import Show from "./show";


function ShowHandler() {
    const { showName } = useParams();

    return window.shows[showName] ? (
        <Show showName={showName} />
    ) : (
        <Fade in>
            <div style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                marginTop: '20vh',
                paddingInline: '20vw'
            }}>
                <RemoveCircleOutlineIcon style={{ margin: "auto", fontSize: '20vh' }} />
                <Typography variant="h2" style={{ margin: "auto", marginTop: 10, fontWeight: '600', marginLeft: 30 }}>
                    Uh oh...
                </Typography>
                <Typography variant="h6" style={{ margin: "auto", marginTop: 10 }}>
                    The show you were looking for doesn&apos;t exist. If you&apos;re sure
                    you entered the URL right, the show may have been removed due to licensing
                    issues.
                </Typography>
            </div>
        </Fade>
    );
}

export default function Shows() {
    console.log("rendering Shows");

    const { path } = useRouteMatch();

    return (
        <RouterSwitch>
            <Route path={path + "/:showName"}>
                <ShowHandler />
            </Route>
            <Route exact path={path}>
                <Fade in>
                    <div
                        style={{
                            width: "92%",
                            height: "100%",
                            marginLeft: "4%",
                            marginRight: "4%",
                            paddingTop: "100px",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{
                                marginBottom: "15px",
                                display: "block",
                                fontWeight: "600",
                            }}
                        >
                            All shows:
                        </Typography>
                        <WindowedShowCards shows={Object.values(window.shows)} />
                    </div>
                </Fade>
            </Route>
        </RouterSwitch>
    );
}