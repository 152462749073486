import CssBaseline from "@material-ui/core/CssBaseline";
import {
	createTheme, responsiveFontSizes, ThemeProvider, StylesProvider
} from "@material-ui/core/styles";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Alerts from "./components/alerts";
import UpdateAlert from "./components/update-alert";
import db from "./db";
import Routes from "./pages";
import FallbackComponent from "./pages/fallback.js";
import LoginPage from "./pages/login.js";

export const darkModeLocalStorageNamspace =
	"AnimeDarkModePreferencePersistence";

export default function App() {
	// const classes = useStyles();
	const [user, setUser] = React.useState(() => {
		if (localStorage.AnimeSignInPersistence) {
			try {
				var user = JSON.parse(localStorage.AnimeSignInPersistence)
				return user
			} catch (exception) {
				delete localStorage.AnimeSignInPersistence
				return undefined
			}
		} else {
			return undefined
		}
	})

	React.useEffect(() => {
		console.log('updating localstorage user')
		try {
			localStorage.AnimeSignInPersistence = JSON.stringify(user)
		} catch (exception) {
			delete localStorage.AnimeSignInPersistence
		}
	}, [user])

	window.user = user;
	window.setUser = setUser;

	console.log('rendering App')
	// const [shows, setShows] = React.useState((localStorage.shows && localStorage.shows !== 'undefined') ? localStorage.showsCompressed ? JSON.parse(decompress(localStorage.shows)) : JSON.parse(localStorage.shows) : [])


	var theme = createTheme({
		palette: {
			type: "dark",
			primary: {
				main: "#3b0087" // #673ab7
			},
			secondary: {
				main: "#FFBC00"
			},
			background: {
				default: "radial-gradient(68.13% 100% at 100% 0, #dd5ce5 0, rgba(221, 92, 229, 0) 100%), linear-gradient(95.52deg, #2e95d3, rgba(46, 149, 211, 0)), linear-gradient(0deg, #5436da, #5436da), #6e6e80"
			},
			text: {
				primary: "#ffffff"
			}
		},
		typography: {
			fontFamily: 'fieldwork, sans-serif',
			h3: {
				fontWeight: '300',
			},
			h4: {
				fontWeight: '300',
			},
		},
		link: {
			fontFamily: 'fieldwork, sans-serif',
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					'@font-face': "fieldwork, sans-serif",
				},
			},
		},
	});

	theme = responsiveFontSizes(theme, { factor: 4 });

	document.documentElement.style.setProperty(
	'--color-text',
	theme.palette.text.primary
	);

	document.documentElement.style.setProperty(
	'--color-primary',
	theme.palette.primary.main
	);

	document.documentElement.style.setProperty(
	'--color-secondary',
	theme.palette.secondary.main
	);

	document.documentElement.style.setProperty(
	'--spacing',
	theme.spacing(1) + "px"	
	);
	// const GlobalStyles = createGlobalStyle`
	// html {
	// 	--color-text: ${theme.palette.text.primary};
	// 	--color-primary: ${theme.palette.primary.main};
	// 	--color-secondary: ${theme.palette.secondary.main};
	// 	--spacing: ${theme.spacing(1)};
	// }`

	React.useEffect(() => db.open(), [])

	React.useEffect(() => {
		window.loaded = true
		document.querySelector("#glyph936").style.opacity = 0
		setTimeout(() => document.querySelector("#glyph936").remove(), 0.3)
	}, [])

	if (localStorage.skipIntroOutro) {
		localStorage.skipOpeningClosing = localStorage.skipIntroOutro
		delete localStorage.skipIntroOutro
	}

	if (!window.shows) {
		if (localStorage.showsCompressed) {
			delete localStorage.shows
			delete localStorage.showsCompressed
		}
		var currentShows = (localStorage.shows && localStorage.shows !== 'undefined') ? JSON.parse(localStorage.shows) : {}

		var shows = currentShows

		if (Array.isArray(currentShows)) {
			shows = {}
			for (var show of currentShows) {
				shows[show.slug] = show
			}
		}

		window.shows = shows

		if (!(localStorage.shows && localStorage.shows !== 'undefined')) {
			fetch('/shows.json')
				.then(response => response.json())
				.then(data => {
					localStorage.showsVersion = parseInt(data.version);
					// localStorage.showsCompressed = "true";
					localStorage.shows = JSON.stringify(data.shows);
					window.shows = data.shows;
				});
		} else {
			fetch("/GetShowsVersion", { cache: "reload" })
				.then(function (body) {
					return body.text();
				}).then(function (data) {
					const version = parseInt(data)
					console.logDebug('current shows version:	', version);
					console.logDebug('using shows version:	', localStorage.showsVersion);
					if (parseInt(localStorage.showsVersion) !== parseInt(version)) {
						console.logDebug('using old shows, downloading new');
						fetch('/shows.json', {
							cache: "reload",
							headers: {
								'Content-Type': 'application/json'
							}
						})
							.then(response => response.json())
							.then(data => {
								console.logDebug('data', data)
								localStorage.showsVersion = parseInt(data.version);
								// localStorage.showsCompressed = "true";
								var shows = data.shows

								if (Array.isArray(data.shows)) {
									shows = {}
									for (var show of data.shows) {
										shows[show.slug] = show
									}
								}

								localStorage.shows = JSON.stringify(shows)
								window.shows = shows;
							});
					}
				});
		}
		if (localStorage.showsCompressed) {
			localStorage.shows = JSON.stringify(window.shows)
			delete localStorage.showsCompressed
		}
	}
	// }, [])

	import("shaka-player-mod/shaka-player.ui").then(shaka => {
		window.shaka = shaka
		window.onShakaLoad && window.onShakaLoad(shaka)
	})

	return (
		<ErrorBoundary fallback={FallbackComponent} showDialog>
			<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					{user ? (
						<Routes />
					) : (
						<LoginPage />
					)}

				</Router>
				<Alerts />
				<UpdateAlert />
			</ThemeProvider>
			</StylesProvider>
		</ErrorBoundary>
	);
}
