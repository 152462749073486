import Fuse from 'fuse.js';
import React from 'react';

const options = {
    // isCaseSensitive: false,
    // includeScore: true,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.4,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: [
      { name: "name", weight: 3 },
      { name: "original", weight: 2 },
      { name: "genres", weight: 1 }
    ]
  };

export default function useSearch(limit, showArray = Object.values(window.shows)) {  
    const [fuse] = React.useState(() => new Fuse(showArray.map(a => ({ ...a, "genres": a.genres.join(" ") })), options))

    return (query) => {
        const results = fuse.search(query)
        results.length = limit
        console.log(results)
        return results.map(a => showArray[a.refIndex])
    }
}