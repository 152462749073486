/* eslint-disable react/prop-types */
import AppContext from ".";
import React from "react";

const ContextProvider = ({ children }) => {
  const [user, setUser] = React.useState({
    signedIn: false,
    new: true,
    token: undefined,
    email: undefined,
    imageUrl: undefined,
    firstName: undefined,
    lastName: undefined,
    prefix: undefined,
  });
  const context = {
    setUser,
    user,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
export default ContextProvider;
