/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

function ShowsCardsNoRef(props, ref) {
  console.log("rendering ShowCards", props);


  var shows = window.shows || {};

  var showsList = props.shows;

  return (
    <Fade in timeout={500}>
      <Grid
        ref={ref}
        style={{
          marginTop: "0px",
          width: "100%",
          zoom: props.isMobile ? 0.8 : 1,
        }}
        container
        spacing={1}
      >
        {showsList.map((show) => {
          var data = show.slug ? show : shows[show];
          if (!data) {
            return null;
          }
          return (
            <Grid item xs={4} sm={3} md={2} xl={1}>
              <Link style={{ textDecoration: 'none' }} to={"/shows/" + data.slug}>
                <Card
                  elevation={1}
                  // onClick={() => history.push("/shows/" + data.slug)}
                  // style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                  id="transluscent"
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      onLoad={(a) => (a.target.className = "animate")}
                      image={
                        "/img/anime/" +
                        data.smallPortrait +
                        (props.noCache ? "?" : "")
                      }
                      width={400}
                      height={600}
                      style={{ width: "100%", height: "auto" }}
                      alt={data.name}
                    />
                    <CardContent
                      style={{
                        marginTop: "-10px",
                        marginBottom: "-5px",
                        // backgroundColor: "#511D9F",
                      }}
                    >
                      <Tooltip title={data.name}>
                        <Typography
                          variant="h6"
                          noWrap
                          style={{ color: "rgba(255,255,255,0.95)", textShadow: "none" }}
                        >
                          {data.name}
                        </Typography>
                      </Tooltip>
                      <Rating
                        name="read-only"
                        value={(data.rating / 100) * 5}
                        readOnly
                        style={{
                          marginTop: "-5px",
                          zoom: window.isMobile ? 0.8 : 1,
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{ color: "rgba(255,255,255,.7)" }}
                      >
                        {[data.year, data.ratingCode, data.isMovie && "MOVIE"]
                          .filter((a) => a)
                          .join(" • ")}
                      </Typography>


                      {/* <div style={{ width: '100%', marginTop: '5px' }}>
                                        {!props.zoomedOut && data.isMovie && (<Chip className={classes.chip} classes={{ label: classes.label }} color="secondary" size="small" label="MOVIE" />)}
                                        {data.spoken && (<Chip className={classes.chip} classes={{ label: classes.label }} color="primary" size="small" label="DUB" />)}
                                        {data.subtitled && (<Chip className={classes.chip} classes={{ label: classes.label }} color="primary" size="small" label="SUB" />)}
                                    </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Fade>
  );
}

export const ShowCards = React.forwardRef(ShowsCardsNoRef);
