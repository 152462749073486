/* eslint-disable react/prop-types */
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Rating from "@material-ui/lab/Rating";
import React from "react";
import { useHistory } from "react-router-dom";
import useSearch from "./utils/useSearch"
import "../App.css";
import "../css/fade.css";
import styles from "./css/search.module.css";

export default function Search(props) {
  const history = useHistory();
  const { shows } = window;
  const { isMobile, setSearching } = props;
  const [AutocompleteValue, setAutocompleteValue] = React.useState(true)
  const showArray = Object.values(shows)
  const search = useSearch(20, showArray)

  const filterOptions = (options, { inputValue }) => {
    return search(inputValue)
  }

  function onChange(event, value) {
    console.log(value)
    setSearching(false);
    if (showArray.includes(value)) {
      history.push("/shows/" + value.slug);
      setAutocompleteValue(prev => !prev)
    }
  }

  const textboxTheme = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#ffffff"
      },
      secondary: {
        main: "#ffffff"
      },
      text: {
        primary: "#ffffff"
      }
    },
    typography: {
      fontFamily: 'fieldwork, sans-serif',
      h3: {
        fontWeight: '300',
      },
      h4: {
        fontWeight: '300',
      },
    },
    link: {
      fontFamily: 'fieldwork, sans-serif',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': "fieldwork",
        },
      },
      MuiPaper: {
        root: { backgroundColor: "rgba(255, 255, 255, 0)", backdropFilter: "blur(5px)" }
      }
    },
  });


  return (
    <ThemeProvider theme={textboxTheme}>
      <Autocomplete
        noOptionsText="Couldn't find any matching shows"
        key={AutocompleteValue}
        onBlur={() => isMobile && setSearching(false)}
        style={{
          flexGrow: 100,
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "10px",
          marginBottom: "10px",
          zoom: isMobile ? 1 : 1,
        }}
        options={showArray}
        classes={{
          textbox: styles.textbox
        }}
        disableListWrap
        filterOptions={filterOptions}
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        ListboxProps={{ style: { background: "radial-gradient(68.13% 100% at 100% 0, #dc5ce567 0, rgba(221, 92, 229, 0) 100%), linear-gradient(95.52deg, #2e94d36b, rgba(46, 149, 211, 0)), linear-gradient(0deg, #5436da6c, #5436da67), rgba(100, 50, 180, 0.836)" } }}
        renderOption={(option) =>
          isMobile ? (
            <React.Fragment>
              <img
                onLoad={(a) => (a.target.className = "animate")}
                alt=""
                width={200}
                height={300}
                style={{
                  height: "40px",
                  width: "auto",
                  marginRight: "20px",
                }}
                src={"/img/anime/" + option.thumbnail + "?"}
              />
              <Typography variant="body1" style={{ marginTop: "3px" }}>
                {option.name}
              </Typography>
            </React.Fragment>
          ) : (
            <div style={{ display: "flex", width: "100%" }}>
              <img
                onLoad={(a) => (a.target.className = "animate")}
                alt=""
                width={200}
                height={300}
                style={{
                  height: "60px",
                  width: "auto",
                  marginRight: "20px",
                }}
                src={"/img/anime/" + option.thumbnail + "?"}
              />
              <Grid container spacing={3} style={{ margin: "auto" }}>
                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    style={{ marginTop: "5px" }}
                  >
                    {option.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Rating
                    name="read-only"
                    value={(option.rating / 100) * 5}
                    readOnly
                    style={{ marginTop: "3px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    style={{ marginTop: "5px" }}
                  >
                    {[
                      option.year,
                      option.ratingCode,
                      option.isMovie && "MOVIE",
                    ]
                      .filter((a) => a)
                      .join(" • ")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for anime by name or genre..."
            variant="filled"
            fullWidth
            autoFocus={isMobile}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
              style: { color: "white" } // disable autocomplete and autofill
            }}
          />
        )}
      />
    </ThemeProvider>
  );
}
