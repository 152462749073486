export default function useDoStuffWithTheApi(
	command,
	token,
	data = {},
	callback
) {
	function request() {
		try {
			let xhr = new XMLHttpRequest();
			xhr.open("POST", "/API", true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						try {
							const json = JSON.parse(xhr.responseText);
							console.logDebug('response', json)
							if (json.result === "Failure") {
								console.logDebug(json);
								console.logDebug(
									"%cBRUH! ^^^^^^^^",
									"color: #f518f5; font-weight: bold; fonst-size: 200%;"
								);
								if (json.reason === "Access denied") {
									console.logDebug('signing out user')
									// localStorage.AnimeSignInPersistence = "{}"
									window.setUser(undefined)
									window.location.reload()
								}
								window.makeAlert(json.reason, 'error')
								callback(true, json, xhr);
							} else {
								callback(false, json, xhr);
							}
						} catch {
							window.makeAlert('Unknown error (1). Please send a support request with the feedback button.', 'error')
							console.logDebug(
								"%cBRUH! ?????",
								"color: #f518f5; font-weight: bold; fonst-size: 200%;"
							);
							callback(true, {}, xhr);
						}
					} else if (xhr.status === 403) {
						window.makeAlert('Blocked by firewall. Please disable your VPN or try refreshing the page.', 'error')
						console.logDebug(
							"%cUser does not have access!",
							"color: #f56042; font-weight: bold; fonst-size: 120%;"
						);
						callback(true, {}, xhr);
					} else {
						window.makeAlert('Unknown error (2). Please send a support request with the feedback button.', 'error')
						console.logDebug(
							"%cUser does not have access!",
							"color: #f56042; font-weight: bold; fonst-size: 120%;"
						);
						callback(true, {}, xhr);
					}
				}
			};
			xhr.send(
				JSON.stringify({
					command: command,
					token: token,
					data: data
				})
			);
		} catch {
			callback(true, {}, 'pog');
		}
	}
	request();
}
