import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Depression() {
  const [open, setOpen] = React.useState(!localStorage.goingAwayDialogThemComingBack);

  const handleClose = () => {
      localStorage.goingAwayDialogThemComingBack = true;
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{id: "frosted"}}
      >
        <DialogTitle>Anime is going away for a bit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Anime sources it&apos;s content from Animelab, but as you might know, on the 6th of December Animelab is going away.
            <br />
            This means that some shows are already unavailable, and on the 6th of December you won&apos;t be able to watch anything.
            <br />
            I&apos;m working on using Funimation as a new source, but this&apos;ll only happen once exams are over.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="text" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}
