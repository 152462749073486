/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// import shaka from 'shaka-player/dist/shaka-player.ui';
import { ButtonBase } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React from "react";
import {
    Link,
    Route,
    Switch as RouterSwitch, useParams, useRouteMatch
} from "react-router-dom";
import WindowedShowCards from "../../components/windowed-show-cards";
import genres from "../../data/genres.json";


function GenreHandler() {
    const { genre } = useParams();

    return genres[genre] ? (
        <Fade in>
            <div
                style={{
                    width: "92%",
                    height: "100%",
                    marginLeft: "4%",
                    marginRight: "4%",
                    paddingTop: "100px",
                }}
            >
                <Typography
                    variant="h4"
                    style={{
                        marginBottom: "15px",
                        display: "block",
                        fontWeight: "600",
                    }}
                >
                    {genres[genre].name}
                </Typography>
                <Typography
                    variant="body1"
                    style={{
                        marginBottom: "15px",
                        display: "block",
                    }}
                >
                    {genres[genre].description}
                </Typography>
                <WindowedShowCards shows={Object.values(window.shows).filter(a => a.genres.includes(genres[genre].name))} />
            </div>
        </Fade>
    ) : (
        <Fade in>
            <div style={{ width: "100%", height: "100%" }}>
                <RemoveCircleOutlineIcon fontSize="large" style={{ margin: "auto" }} />
                <Typography variant="h3" style={{ margin: "auto", marginTop: 10 }}>
                    Uh oh...
                </Typography>
                <Typography variant="body1" style={{ margin: "auto", marginTop: 10 }}>
                    The genre you were looking for doesn&apos;t exist. If you&apos;re sure
                    you spelled it right, the show may have been removed due to licensing
                    issues.
                </Typography>
            </div>
        </Fade>
    );
}


export default function Router(props) {
    console.log("rendering Genres");

    const { path } = useRouteMatch();

    return (
        <RouterSwitch>
            <Route exact path={path}>
                <Fade in>
                    <div
                        style={{
                            width: "92%",
                            height: "100%",
                            marginLeft: "4%",
                            marginRight: "4%",
                            paddingTop: "100px",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{
                                marginBottom: "15px",
                                display: "block",
                                fontWeight: "600",
                            }}
                        >
                            Genres:
                        </Typography>
                        <Genres />
                    </div>
                </Fade>
            </Route>
            <Route path={path + "/:genre"}>
                <GenreHandler />
            </Route>
        </RouterSwitch>
    );
}

function Genres() {
    return (
        <Grid container spacing={2}>
            {Object.values(genres).map(({ name, slug, description, image }) => (
                <Grid item xs={6} sm={4} md={3} key={name}>
                    <Link to={`/genres/${slug}`}>
                        <Paper
                            component={ButtonBase}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(130, 0, 135, .3)"
                                // backgroundImage: `url("${image}")`,
                                // backgroundSize: "cover",
                                // backgroundPosition: "center",
                                // backgroundRepeat: "no-repeat",
                                // paddingBottom: "56.25%",
                                // top: 0,
                                // bottom: 0,
                                // left: 0,
                                // right: 0
                            }}
                            id="transluscent"
                        >
                            <img onLoad={(a) => (a.target.className = "animate")} src={image} style={{ width: '100%', height: 'auto' }} height={288} width={512} />
                            <Typography
                                variant="h4"
                                style={{
                                    // marginBottom: "15px",
                                    // display: "block",
                                    fontWeight: "600",
                                    position: 'absolute',
                                    margin: 'auto'
                                }}
                            >
                                {name}
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
}