/* eslint-disable react/prop-types */
// import shaka from "shaka-player-mod/shaka-player.ui";
import React from "react";

/**
 * A React component for shaka-player.
 * @param {string} src
 * @param {shaka.extern.PlayerConfiguration} config
 * @param {boolean} autoPlay
 * @param {number} width
 * @param {number} height
 * @param ref
 * @returns {*}
 * @constructor
 */
function ShakaPlayer({ src, config, chromeless, className, ...rest }, ref) {
  const uiContainerRef = React.useRef(null);
  const videoRef = React.useRef(null);

  const [player, setPlayer] = React.useState(null);
  const [ui, setUi] = React.useState(null);
  const [shaka, setShaka] = React.useState(window.shaka);

  // Effect to handle component mount & mount.
  // Not related to the src prop, this hook creates a shaka.Player instance.
  // This should always be the first effect to run.
  React.useEffect(() => {
    if (shaka) {
      const player = new shaka.Player(videoRef.current);

      player.configure({
        streaming: {
          rebufferingGoal: 5,
          bufferingGoal: 20,	
        }
      });

      setPlayer(player);

      window.player = player;
      // window.shaka = shaka;

      let ui;
      if (!chromeless) {
        const ui = new shaka.ui.Overlay(
          player,
          uiContainerRef.current,
          videoRef.current
        );
        ui.configure({
          doubleClickForFullscreen: false,
        });
        setUi(ui);
      }

      return () => {
        player.destroy();
        if (ui) {
          ui.destroy();
        }
      }
    } else {
      window.onShakaLoad = setShaka
    }
  }, [shaka]);

  // Keep shaka.Player.configure in sync.
  React.useEffect(() => {
    if (player && config) {
      player.configure(config);
    }
  }, [player, config]);

  // Load the source url when we have one.
  React.useEffect(() => {
    if (player && src) {
      player.load(src);
    }
  }, [player, src]);

  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(
    ref,
    () => ({
      get player() {
        return player;
      },
      get ui() {
        return ui;
      },
      get videoElement() {
        return videoRef.current;
      },
      get shaka() {
        return shaka;
      },
    }),
    [player, ui]
  );

  // React.useEffect(() => {
  //   var myapp = {};

  //   // Use shaka.ui.Element as a base class
  //   myapp.SkipButton = class extends shaka.ui.Element {
  //     constructor(parent, controls) {
  //       super(parent, controls);

  //       // The actual button that will be displayed
  //       this.button_ = document.createElement('button');
  //       this.button_.textContent = 'Skip current video';
  //       this.parent.appendChild(this.button_);

  //       // Listen for clicks on the button to start the next playback
  //       this.eventManager.listen(this.button_, 'click', () => {
  //         const nextManifest = /* Your logic to pick the next video to be played */
  //           myapp.getNextManifest();

  //         // shaka.ui.Element gives us access to the player object as member of the class
  //         this.player.load(nextManifest);
  //       });
  //     }
  //   };

  //   // Factory that will create a button at run time.
  //   myapp.SkipButton.Factory = class {
  //     create(rootElement, controls) {
  //       return new myapp.SkipButton(rootElement, controls);
  //     }
  //   };

  //   // Register our factory with the controls, so controls can create button instances.
  //   shaka.ui.Controls.registerElement(
  // /* This name will serve as a reference to the button in the UI configuration object */ 'skip',
  //     new myapp.SkipButton.Factory()
  //   );

  //   uiConfig['controlPanelElements'] = ['rewind', 'fast_forward', 'skip'];
  // }, [videoRef])

  return (
    <div ref={uiContainerRef} className={className}>
      <video
        ref={videoRef}
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
        {...rest}
      />
    </div>
  );
}

export default React.forwardRef(ShakaPlayer);
