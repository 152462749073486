/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { setTag } from "@sentry/react";
import React from "react";
import {
  Route,
  Switch as RouterSwitch,
  useHistory, useParams, useRouteMatch
} from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import AppBar from "../appbar";
import "../css/fade.css";
import Genres from "./genres";
import Home from "./home";
import Shows from "./shows/shows.js";
import Depression from "../components/depression"

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // minHeight: "100%",
    // height: "auto",
    paddingBottom: "50px",
    minHeight: "100%",
    // backgroundColor: theme.palette.background.default,
    // paddingBottom: 100
  },
  appBar: {
    zIndex: 1,
  },
  toolbar: {
    width: "100%",
  },
  toolbarButtons: {},
  // toolbarButton: {
  // 	// marginRight: theme.spacing(1),
  // 	// marginLeft: theme.spacing(1),
  // 	color: theme.palette.primary.contrastText
  // },
  // avatar: {
  // 	width: theme.spacing(6),
  // 	height: theme.spacing(6),
  // 	marginRight: theme.spacing(1),
  // 	marginLeft: theme.spacing(1),
  // 	cursor: "pointer",
  // },
  // toolbarRight: {
  // 	display: "flex",
  // 	alignItems: "center",
  // 	marginLeft: "auto"
  // },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  drawerContainer: {
    overflow: "auto",
  },
  sidebar: {
    height: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  mainContent: {
    display: "flex",
    // backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    width: "100%",
  },
  label: {
    // fontFamily: 'fieldwork, sans-serif',
    marginBottom: "-2px",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  popover: {
    // background: theme.custom.palette.profilePopColor,
    width: theme.spacing(40),
    backgroundColor: "#511D9F",
    // backgroundColor: "#3b0087"
    // borderRadius: theme.shape.borderRadius
  },
  container: {
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: "2px solid " + theme.palette.secondary.main,
    margin: theme.spacing(1),
    // background: theme.palette.background.default
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  userName: {
    // ...theme.custom.fontFamily.metropolis,
    fontSize: "1rem",
    fontWeight: 500,
  },
  userEmail: {
    // ...theme.custom.fontFamily.roboto,
    fontSize: "0.9rem",
  },
  bar: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSignout: {
    // ...theme.custom.fontFamily.metropolis,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 2),
    fontSize: "0.8rem",
    fontWeight: 500,
    textTransform: "none",
  },
  textbox: {
    // ...theme.custom.fontFamily.metropolis,
    color: 'white'
  },
}));

function ShowRedirectHandler() {
  const history = useHistory();
  const { showName } = useParams();

  if (window.shows[showName]) {
    history.replace("/shows/" + showName);
  } else {
    history.replace("/");
  }

  return (
    <Typography variant="h3" style={{ margin: "auto" }}>
      Redirecting you to your show...
    </Typography>
  );
}

export default function Dashboard() {
  console.log("rendering Dashboard");
  const classes = useStyles();
  const user = window.user;

  const [appBarShown, setAppBarShown] = React.useState(true);

  const { path } = useRouteMatch();
  //const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600 || window.innerHeight < 600)

  window.onresize = () => setIsMobile(window.innerWidth < 600 || window.innerHeight < 600)

  window.setAppBarShown = setAppBarShown;
  window.isMobile = isMobile;

  setTag("username", user.fullName);

  document.title = "Anime";

  return (
    <Fade in>
      <div className={classes.root}>
        {appBarShown && <AppBar isMobile={isMobile} />}
        <RouterSwitch>
          <Route path={path + "shows"}>
            <Shows />
          </Route>
          <Route path={path + "genres"}>
            <Genres />
          </Route>
          <Route path={path + ":showName"}>
            <ShowRedirectHandler />
          </Route>
          <Route path={path}>
            <Home isMobile={isMobile} />
          </Route>
        </RouterSwitch>
				<Depression />
      </div>
    </Fade>
  );
}
