/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
// import Carousel from 'react-material-ui-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../../css/fade.css";

const WhiteLinearProgress = withStyles(() => ({
  // colorPrimary: {
  //     backgroundColor: '#3b0087',
  // },
  bar: {
    backgroundColor: "#FFFFFF",
  },
}))(LinearProgress);

const NiceChip = withStyles(() => ({
  // colorPrimary: {
  //     backgroundColor: '#3b0087',
  // },
  label: {
    marginBottom: "-2px",
    color: "rgba(255,255,255,.9)",
  },
}))(Chip);

function EpisodeCard({
  episode,
  oneEpisodeProgress,
  episodeImage,
  numberOfCards,
}) {
  const ref = React.createRef();
  const [showLink, setShowLink] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  return (
    <Grid item xs={12 / numberOfCards}>
      <Card
        elevation={5}
        onClick={() => window.goToEpisode(episode.sequence)}
        style={{ textAlign: "left" }}
        id="transluscent"
      >
        <CardActionArea>
          <CardMedia
            component="img"
            image={episodeImage}
            width={512}
            height={288}
            onLoad={(a) => (a.target.className = "animate")}
            style={{ width: "100%", height: "auto" }}
            alt={episode.name + "preview"}
          />
          <WhiteLinearProgress
            variant="determinate"
            value={
              (100 * oneEpisodeProgress.time) / oneEpisodeProgress.duration
            }
          />
          <CardContent
            style={{
              marginTop: "-5px",
              // backgroundColor:
              //   i * numberOfCards + num === 1
              //     ? "#3b0087"
              //     : episodes[i * numberOfCards + num - 1] ||
              //       episodes[i * numberOfCards + num - 2] ||
              //       episodes[i * numberOfCards + num - 3]
              //       ? (
              //         episodes[i * numberOfCards + num - 1] ||
              //         episodes[i * numberOfCards + num - 2] ||
              //         episodes[i * numberOfCards + num - 3]
              //       ).season.seasonNumber === episode.season.seasonNumber
              //         ? "#3b0087"
              //         : "#511D9F"
              //       : "#3b0087",
              // backgroundColor: "rgba(59, 0, 135, .2)"
            }}
          >
            <Typography variant="h6" style={{ marginTop: "-3px" }}>
              Episode {episode.episodeNumber}
            </Typography>
            <div style={{ minHeight: "2.5em" }}>
              {showLink ? (
                <Tooltip title={episode.name}>
                  <Typography
                    variant="body2"
                    ref={ref}
                    style={{
                      lineHeight: "1.2em",
                      color: "rgba(255,255,255,.9)",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {episode.name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  variant="body2"
                  ref={ref}
                  style={{
                    lineHeight: "1.2em",
                    color: "rgba(255,255,255,.9)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {episode.name}
                </Typography>
              )}
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              {episode.hasDubbedVideo && (
                <NiceChip
                  size="small"
                  variant="outlined"
                  label="DUB"
                  style={{
                    fontSize: "13px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                />
              )}
              {episode.hasSubbedVideo && (
                <NiceChip
                  size="small"
                  label="SUB"
                  variant="outlined"
                  style={{
                    fontSize: "13px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                />
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default function EpisodesViewer({
  i,
  episodes,
  numberOfCards,
  episodeProgress,
  index,
  isMobile,
}) {
  console.log("rendering EpisodesViewer");

  return (
    Math.abs(index - Math.floor(i / (isMobile ? 3 : 1))) < 2 && (
      <Grid style={{ width: "100%", paddingInline: '30px', marginLeft: '2px', marginBottom: '30px' }} container spacing={2}>
        {[...Array(numberOfCards).keys()].map((num) => {
          var episode = episodes[i * numberOfCards + num];
          if (episode === undefined) {
            return <Grid item xs={12 / numberOfCards} />;
          }
          var oneEpisodeProgress = episodeProgress
            ? episodeProgress[i * numberOfCards + num] || {
              time: 0,
              duration: 1,
            }
            : { time: 0, duration: 1 };
          // console.logDebug(episodeProgress, oneEpisodeProgress)
          var episodeImage = "";
          try {
            episodeImage =
              "https://proxy.benhong.me/corsproxy/?apiurl=" +
              []
                .concat(...episode.images.map((a) => a.imageInstances))
                .sort((a, b) => a.imageInfo.height - b.imageInfo.height)[0]
                .imageInfo.fullPath;
          } catch (err) {
            console.logDebug("no episode image");
          }
          return (
            <EpisodeCard
              episode={episode}
              oneEpisodeProgress={oneEpisodeProgress}
              episodeImage={episodeImage}
              numberOfCards={numberOfCards}
              i={i}
            />
          );
        })}
      </Grid>
    )
  );
}