import Dexie from 'dexie';

Dexie.debug = "dexie";
// Create your instance
const db = new Dexie("showData");

// Define your schema
db.version(1).stores({
    episodes: "[show+sequence], show",
    signed: "[id+filename], url",
    episodeProgress: "[show+sequence], show"
});

db.on("populate", function () {
    var episodes = {}
    if (localStorage.episodeCache && !localStorage.compressedEpisodes) {
        episodes = JSON.parse(localStorage.episodeCache);
    }

    var show;
    for (show in episodes) {
        var episode;
        for (episode of Object.values(episodes[show])) {
            db.episodes.add({ show: show, sequence: episode.sequence, data: episode });
        }
    }

    delete localStorage.episodeCache

    var signedVideos = JSON.parse(localStorage.signed || "{}")

    var signed;
    var id;
    var filename;
    for (signed in signedVideos) {
        id = signed.match(/\d*/)[0];
        filename = signed.replace(id, '')
        db.signed.add({ id: parseInt(id), filename: filename, url: signedVideos[signed] });
    }

    delete localStorage.signed

    var progress = JSON.parse(localStorage.progress || "{}")

    for (show in progress) {
        console.logDebug(show)
        if (episodes[show] && episodes[show][parseInt(progress[show].episode)]) {
            db.episodeProgress.add({ show: show, sequence: parseInt(progress[show].episode), time: progress[show].time, duration: episodes[show][parseInt(progress[show].episode)].duration })
            progress[show].data = episodes[show][parseInt(progress[show].episode)]
            progress[show].duration = episodes[show][parseInt(progress[show].episode)].duration
            progress[show].date = Date.now()
        }
    }

    localStorage.progress = JSON.stringify(progress)
});

// Open the database
db.open().then(() => console.log('Database opened'))
export default db;
window.db = db
// import Dexie from 'dexie';

// const db = new Dexie('myDb');
// db.version(1).stores({
//     friends: `name, age`
// });



// db.open()