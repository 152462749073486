/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import NProgress from "nprogress";
import React from "react";
import "../App.css";
import "../css/fade.css";
import callAPI from "../utils/api.js";

export default function Feedback(props) {
  console.log("rendering Feedback");
  const user = window.user;

  const [feedbackValues, setFeedbackValues] = React.useState({
    rating: 1,
    type: "feedback",
    text: "",
  });

  function sendFeedbackToServer() {
    NProgress.start();
    callAPI(
      "SendFeedback",
      user.token,
      feedbackValues,
      function (err) {
        NProgress.done();
        if (!err) {
          window.makeAlert(
            feedbackValues.type === "feedback"
              ? "Feedback submitted"
              : "Support request submitted",
            "success"
          );
        }
      }
    );
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        props.setFeedbackModalOpen(false);
      }}
    >
      <DialogTitle id="form-dialog-title">
        {feedbackValues.type === "feedback"
          ? "Send feedback about Anime"
          : "Report an issue with Anime"}
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.setFeedbackModalOpen(false);
          sendFeedbackToServer();
        }}
      >
        <FormControl>
          <DialogContent>
            <DialogContentText>
              We&apos;re working hard on making Anime the best possible
              experience for you
              {feedbackValues.type === "feedback"
                ? " and we would love to hear what you think about it!"
                : ". To help us fix it as soon as possible, please describe the issue in detail and explain how you encountered it."}
            </DialogContentText>
            <TextField
              margin="dense"
              id="feedback-message"
              label={
                feedbackValues.type === "feedback"
                  ? "Feedback"
                  : "Describe the issue"
              }
              placeholder={
                feedbackValues.type === "feedback"
                  ? "I love Anime because the website looks so dang good."
                  : "I'm having a problem with... [describe what the problem is with]. I can see... [describe what you can see]. I think that I should be seeing... [describe what you whink you should be seeing]. To make this happen I... [list of steps to replicate the problem]."
              }
              variant="filled"
              color="secondary"
              multiline={true}
              rows={3}
              fullWidth={true}
              value={feedbackValues.message}
              onChange={(e) => {
                setFeedbackValues({
                  ...feedbackValues,
                  text: e.target.value,
                });
              }}
              required={true}
            />
            {feedbackValues.type === "feedback" && (
              <Box m={0} borderColor="transparent" component="fieldset">
                <Typography component="legend">
                  Rate your experience with Anime
                </Typography>
                <Rating
                  name="feedback-rating"
                  size="large"
                  value={feedbackValues.rating}
                  onChange={(e, newValue) => {
                    // newValue only works in this component btw
                    setFeedbackValues({
                      ...feedbackValues,
                      rating: newValue,
                    });
                  }}
                  required={true}
                />
              </Box>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={feedbackValues.type === "request" ? true : false}
                  onChange={(e) => {
                    setFeedbackValues({
                      ...feedbackValues,
                      type: e.target.checked ? "request" : "feedback",
                    });
                  }}
                  name="feedback-request-toggle"
                />
              }
              label="Support request"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setFeedbackModalOpen(false);
              }}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                props.setFeedbackModalOpen(false);
                sendFeedbackToServer();
              }}
              variant="contained"
              color="secondary"
              disabled={
                feedbackValues.text.length < 5 ||
                feedbackValues.text.length > 600
              }
            >
              Submit
            </Button>
          </DialogActions>
        </FormControl>
      </form>
    </Dialog>
  );
}