/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import { ShowCards } from "./show-cards.js";
import "../css/fade.css";
import { useMeasure } from "react-use";


function useElementOnScreen(options) {
  const containerRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  function callbackFunction(entries) {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(entry.isIntersecting);
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const observedRef = containerRef.current;
    if (observedRef) observer.observe(observedRef);
    return () => {
      if (observedRef) observer.unobserve(observedRef);
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
}

const Row = ({ i, isMobile, sizes, shows }) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "100px 0px 3000px 0px",
    threshold: 0,
  });

  return (
    <div
      ref={containerRef}
      style={{
        height: sizes.height,
        width: sizes.width,
        zoom: isMobile ? 0.8 : 1,
      }}
    >
      {isVisible || i === 0 ? (
        <ShowCards shows={shows.slice(12 * i, 12 * i + 12)} noCache />
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default function WindowedShowCards({ shows, isMobile }) {
  const [sizeRef, sizeMeasurements] = useMeasure();

  return (
    <React.Fragment>
      <div
        ref={sizeRef}
        style={{
          zoom: isMobile ? 0.8 : 1,
        }}
      >
        <ShowCards shows={shows.slice(0, 12)} noCache />
      </div>

      {sizeMeasurements &&
        sizeMeasurements.height &&
        [...Array(Math.ceil(shows.length / 12) - 1).keys()].map((i) => (
          <Row
            i={i + 1}
            sizes={sizeMeasurements}
            shows={shows}
            isMobile={isMobile}
          />
        ))}
    </React.Fragment>
  );
}

// const MomoizedWindowedShowCards = React.memo(RecentEpisodeCard)

// export default MomoizedWindowedShowCards
