/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
//import * as dashjs from "../dash.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NProgress from "nprogress";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import "shaka-player-mod/controls.css";
import ShakaPlayer from "../../components/shaka-player";
import db from "../../db";
import callAPI from "../../utils/api.js";

const useStyles = makeStyles((theme) => ({
  classPage: {
    padding: theme.spacing(8),
  },
  storePage: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  classSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  classSectionItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  clasSectionPaper: {
    padding: theme.spacing(2),
    width: "95%",
  },
  classSettingInput: {
    minWidth: 160,
    margin: {
      top: theme.spacing(1),
      bottom: theme.spacing(1),
      right: theme.spacing(2),
    },
  },
  episodeCard: {
    backgroundColor: "primary",
    color: "primary",
  },
  track: {
    color: "white",
  },
  rail: {
    color: "grey",
  },
  thumb: {
    color: "white",
    backgroundColor: "white",
    boxShadow: "0 0 10px  rgba(0,0,0,0.6)",
  },
  player: {
    height: "100%",
    width: "100%",
  },
  label: {
    // fontFamily: 'fieldwork, sans-serif',
    marginBottom: "-2px",
  },
  select: {
    marginTop: "-0.125rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  // h6: {
  //     [theme.breakpoints.down('sm')]: {
  //         fontSize: '1rem',
  //     }
  // },
  // h5: {
  //     [theme.breakpoints.down('sm')]: {
  //         fontSize: '1.125rem',
  //     }
  // },
  // h3: {
  //     [theme.breakpoints.down('sm')]: {
  //         fontSize: '2rem',
  //     }
  // },
  // h4: {
  //     [theme.breakpoints.down('sm')]: {
  //         fontSize: '1.5rem',
  //         // lineHeight: '1.5rem',
  //         // marginTop: '5px',
  //     }
  // },
}));

export default function Player(props) {
  console.log("rendering episode player");
  const user = window.user;
  const history = useHistory();
  console.logDebug("history, location", history);
  const { showName, episode } = useParams();
  const { show } = props;
  // const [player, setPlayer] = React.useState(null);
  const [showOpeningSkip, setShowOpeningSkip] = React.useState(false);
  const [showClosingSkip, setShowClosingSkip] = React.useState(false);
  const [controlsOpen, setControlsOpen] = React.useState(true);
  const controllerRef = React.useRef(null);
  const [playing, setPlaying] = React.useState(false);
  // const [orientation, setOrientation] = React.useState(window.screen.orientation && window.screen.orientation.type);
  const classes = useStyles();

  const skippedOpening = React.useRef(false);
  const skippedClosing = React.useRef(false);

  if (!localStorage.progress) {
    localStorage.progress = "{}";
  }

  var episodes = props.episodes || {};

  var storedProgress = JSON.parse(localStorage.progress);

  if (
    storedProgress[showName] &&
    storedProgress[showName].data &&
    !episodes[episode]
  ) {
    episodes[storedProgress[showName].data.sequence] =
      storedProgress[showName].data;
  }

  var episodeData = episodes[episode];

  if (episodeData === undefined) {
    console.log("could not find episode data");
    if (history.length === 0) {
      history.replace("/shows/" + showName);
    } else {
      history.goBack();
    }
  }

  var videoList = {};

  var i;

  for (i of episodeData.videoList) {
    videoList[i.language.name] = i;
  }

  console.logDebug(
    "preferred language",
    localStorage.getItem("preferredLanguage")
  );

  var preferredLanguage = localStorage.preferredLanguage;

  if (preferredLanguage === undefined) {
    localStorage.preferredLanguage = "English";
    preferredLanguage = "English";
  }

  var videoData;
  var sources = {};

  for (videoData of episodeData.videoList) {
    sources[videoData.language.name] = [];
    for (i of videoData.videoInstances) {
      var definition;
      var label;
      if (i.videoQuality.name === "SD HLS") {
        label = "auto SD 1";
        definition = 490;
      } else if (i.videoQuality.name === "HD HLS") {
        label = "auto HD 1";
        definition = 2000;
      } else if (i.videoQuality.name === "SD DASH") {
        label = "auto SD";
        definition = 500;
      } else if (i.videoQuality.name === "HD DASH") {
        label = "auto HD";
        definition = 2100;
      } else {
        label = i.videoQuality.name;
        definition = parseInt(i.videoQuality.name.replace("p", ""));
      }
      sources[videoData.language.name].push({
        language: videoData.language.name,
        filename: i.filename,
        format: i.videoQuality.videoFormat.name,
        src: i.httpUrl,
        id: i.id,
        label: label,
        res: definition,
      });
    }
    sources[videoData.language.name].sort((a, b) =>
      a.res > b.res ? -1 : b.res > a.res ? 1 : 0
    );
  }

  var defaultSource;
  var defaultPreferredLanguageSources = Object.values(sources)[0];
  if (Object.keys(sources).includes(preferredLanguage)) {
    defaultPreferredLanguageSources = sources[preferredLanguage];
  }
  var definitionsList = defaultPreferredLanguageSources.map(
    (sourceToGetDefinitionOf) => sourceToGetDefinitionOf.res
  );
  console.logDebug("definitions", definitionsList);
  if (definitionsList.includes(2000) || definitionsList.includes(2100)) {
    defaultSource = defaultPreferredLanguageSources[0];
  } else if (localStorage.preferredDefinition) {
    if (definitionsList.includes(parseInt(localStorage.preferredDefinition))) {
      defaultSource =
        defaultPreferredLanguageSources[
        definitionsList.indexOf(parseInt(localStorage.preferredDefinition))
        ];
    } else {
      defaultSource = defaultPreferredLanguageSources[0];
    }
  } else {
    defaultSource = defaultPreferredLanguageSources[0];
  }

  const [source, setSource] = React.useState(defaultSource);

  const [sourceUrl, setSourceUrl] = React.useState();

  window.setSourceUrl = setSourceUrl;

  console.logDebug("source", source);
  console.logDebug("sources", sources);

  // React.useEffect(() => {
  //     setAppBarShown(false);
  //     return () => setAppBarShown(true)
  // }, []);

  React.useEffect(() => {
    return props.updateProgress;
  }, []);

  React.useEffect(() => {
    return () => {
      if (document.fullscreenElement) {
        if (window.screen.orientation) {
          window.screen.orientation.unlock();
        }
        document.exitFullscreen();
      }
    };
  }, []);

  React.useEffect(() => {
    async function getUrl(signingSource, signingEpisodeData, setAsPlaying) {
      console.log("getting video url for", signingEpisodeData.shortCode);
      // controllerRef.current.videoElement.currentTime = 0
      if (signingSource.src) {
        if (localStorage.signed) {
          localStorage.signed = JSON.stringify({
            ...JSON.parse(localStorage.signed),
            [signingSource.id.toString() + signingSource.filename]:
              signingSource.url,
          });
        } else {
          localStorage.signed = JSON.stringify({
            [signingSource.id.toString() + signingSource.filename]:
              signingSource.url,
          });
        }
        db.signed.put({
          id: signingSource.id,
          filename: signingSource.filename,
          url: signingSource.url,
        });
        if (setAsPlaying) {
          setSourceUrl(signingSource.url);
        } // Don't use player.load because if url is cached, the url is loaded isntantly before the player has actually loaded
      } else {
        var dbSigned = await db.signed.get({
          id: signingSource.id,
          filename: signingSource.filename,
        });
        if (dbSigned) {
          if (setAsPlaying) {
            setSourceUrl(dbSigned.url);
          }
        } else {
          console.log("signing video url for", signingEpisodeData.shortCode);
          NProgress.start();
          callAPI(
            "Sign",
            user.token,
            {
              show: signingEpisodeData.showTitle,
              episode: signingEpisodeData.shortCode,
              id: signingSource.id,
              filename: signingSource.filename,
            },
            function (err, res, xhr) {
              NProgress.done();
              if (!err) {
                db.signed.put({
                  id: signingSource.id,
                  filename: signingSource.filename,
                  url: res.url,
                });
                if (setAsPlaying) {
                  setSourceUrl(res.url);
                }
              }
            }
          );
        }
      }
    }
    getUrl(source, episodeData, true).then(() => {
      setTimeout(() => {
        var nextEpisodeData = window.episodes[parseInt(episode) + 1];

        if (nextEpisodeData) {
          var nextEpisodeVideoList = {};

          var i;

          for (i of nextEpisodeData.videoList) {
            nextEpisodeVideoList[i.language.name] = i;
          }

          var nextEpisodeVideoData;
          var nextEpisodeSources = {};

          for (nextEpisodeVideoData of nextEpisodeData.videoList) {
            nextEpisodeSources[nextEpisodeVideoData.language.name] = [];
            for (i of nextEpisodeVideoData.videoInstances) {
              var definition;
              var label;
              if (i.videoQuality.name === "SD HLS") {
                label = "auto SD 1";
                definition = 490;
              } else if (i.videoQuality.name === "HD HLS") {
                label = "auto HD 1";
                definition = 2000;
              } else if (i.videoQuality.name === "SD DASH") {
                label = "auto SD";
                definition = 500;
              } else if (i.videoQuality.name === "HD DASH") {
                label = "auto HD";
                definition = 2100;
              } else {
                label = i.videoQuality.name;
                definition = parseInt(i.videoQuality.name.replace("p", ""));
              }
              nextEpisodeSources[nextEpisodeVideoData.language.name].push({
                language: nextEpisodeVideoData.language.name,
                filename: i.filename,
                format: i.videoQuality.videoFormat.name,
                src: i.httpUrl,
                id: i.id,
                label: label,
                res: definition,
              });
            }
            nextEpisodeSources[nextEpisodeVideoData.language.name].sort(
              (a, b) => (a.res > b.res ? -1 : b.res > a.res ? 1 : 0)
            );
          }

          var nextEpisodeDefaultSource;
          var nextEpisodeDefaultPreferredLanguageSources =
            Object.values(nextEpisodeSources)[0];
          if (Object.keys(nextEpisodeSources).includes(preferredLanguage)) {
            nextEpisodeDefaultPreferredLanguageSources =
              nextEpisodeSources[preferredLanguage];
          }
          var definitionsList = nextEpisodeDefaultPreferredLanguageSources.map(
            (sourceToGetDefinitionOf) => sourceToGetDefinitionOf.res
          );
          console.logDebug("definitions", definitionsList);
          if (
            definitionsList.includes(2000) ||
            definitionsList.includes(2100)
          ) {
            nextEpisodeDefaultSource =
              nextEpisodeDefaultPreferredLanguageSources[0];
          } else if (localStorage.preferredDefinition) {
            if (
              definitionsList.includes(
                parseInt(localStorage.preferredDefinition)
              )
            ) {
              nextEpisodeDefaultSource =
                nextEpisodeDefaultPreferredLanguageSources[
                definitionsList.indexOf(
                  parseInt(localStorage.preferredDefinition)
                )
                ];
            } else {
              nextEpisodeDefaultSource =
                nextEpisodeDefaultPreferredLanguageSources[0];
            }
          } else {
            nextEpisodeDefaultSource =
              nextEpisodeDefaultPreferredLanguageSources[0];
          }
          getUrl(nextEpisodeDefaultSource, nextEpisodeData);
        }
      }, 1000);
    });
  }, [source]);

  function handleLanguageChange(event) {
    localStorage.preferredLanguage = event.target.value;
    setSource(sources[event.target.value][0]);
  }

  function handleQualityChange(event) {
    var sourceToSet;
    var videoSource;
    console.logDebug("sources", sources);
    console.logDebug("preferred language", preferredLanguage);
    localStorage.preferredDefinition = event.target.value;
    if (sources[preferredLanguage]) {
      for (videoSource of sources[preferredLanguage]) {
        if (videoSource.res === parseInt(event.target.value)) {
          sourceToSet = videoSource;
        }
      }
    } else {
      for (videoSource of Object.values(sources)[0]) {
        if (videoSource.res === parseInt(event.target.value)) {
          sourceToSet = videoSource;
        }
      }
    }
    setSource(sourceToSet);
  }

  console.log("episodes", episodes, props.episodes)

  function nextEpisode() {
    console.log("next video");
    if (!window.goingToNext) {
      window.goingToNext = true;
      for (var i of [...Array(6).keys()]) {
        // console.log('checking if episode exists', i, episode, parseInt(episode) + 1 + i, episodes[parseInt(episode) + 1 + i])
        if (episodes[parseInt(episode) + 1 + i]) {
          controllerRef.current.videoElement.currentTime = 0;
          console.log(
            "adding to history",
            "/shows/" + showName + "/" + (parseInt(episode) + 1 + i).toString()
          );
          history.replace(
            "/shows/" + showName + "/" + (parseInt(episode) + 1 + i).toString()
          );
          return;
        }
      }
      console.log("episodes", episodes, props.episodes)
      console.log("adding to history", "/shows/" + showName);
      if (history.length === 0) {
        history.replace("/shows/" + showName);
      } else {
        history.goBack();
      }
    }
  }

  React.useEffect(() => {

  }, [props.episodes])

  function prevEpisode() {
    console.logDebug("prev video");
    if (parseInt(episode) === 1) {
      if (history.length === 0) {
        history.replace("/shows/" + showName);
      } else {
        history.goBack();
      }
    } else {
      // controllerRef.current.videoElement.currentTime = 0
      history.replace(
        "/shows/" + showName + "/" + (parseInt(episode) - 1).toString()
      );
    }
  }

  function seekTenSecondsBack() {
    controllerRef.current.videoElement.currentTime -= 10;
  }

  function seekTenSecondsForward() {
    controllerRef.current.videoElement.currentTime += 10;
  }

  function skipSection() {
    if (Object.values(aips).length === 0) {
      controllerRef.current.videoElement.currentTime += 30;
    } else {
      var aipSeconds = Object.values(aips)
        .filter((aip) => aip >= controllerRef.current.videoElement.currentTime)
        .sort((a, b) => a - b);
      console.logDebug("aip seconds", aipSeconds);
      if (aipSeconds[0]) {
        controllerRef.current.videoElement.currentTime = aipSeconds[0];
      } else {
        nextEpisode();
      }
    }
  }

  React.useEffect(() => {
    var defaultSource;
    var defaultPreferredLanguageSources = Object.values(sources)[0];
    if (Object.keys(sources).includes(preferredLanguage)) {
      defaultPreferredLanguageSources = sources[preferredLanguage];
    }
    var definitionsList = defaultPreferredLanguageSources.map((a) => a.res);
    console.logDebug("definitions", definitionsList);
    if (definitionsList.includes(2000) || definitionsList.includes(2100)) {
      defaultSource = defaultPreferredLanguageSources[0];
    } else if (localStorage.preferredDefinition) {
      if (
        definitionsList.includes(parseInt(localStorage.preferredDefinition))
      ) {
        defaultSource =
          defaultPreferredLanguageSources[
          definitionsList.indexOf(parseInt(localStorage.preferredDefinition))
          ];
      } else {
        defaultSource = defaultPreferredLanguageSources[0];
      }
    } else {
      defaultSource = defaultPreferredLanguageSources[0];
    }
    setSource(defaultSource);
    setShowOpeningSkip(false);
    setShowClosingSkip(false);
    skippedOpening.current = false;
    skippedClosing.current = false;
  }, [episode]);

  React.useEffect(() => {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: episodeData.showTitle,
        artist: show.isMovie
          ? "anime.benhong.me"
          : episodeData.shortCode + " - " + episodeData.name,
        album: "anime.benhong.me",
        artwork: [
          {
            src: "/img/anime/" + show.square,
            sizes: "1400x1400",
            type: "image/jpg",
          },
        ],
      });

      navigator.mediaSession.setActionHandler(
        "previoustrack",
        seekTenSecondsBack
      );
      navigator.mediaSession.setActionHandler("nexttrack", skipSection);
      // navigator.mediaSession.setActionHandler('pause', controllerRef.current.videoElement.pause);
      // navigator.mediaSession.setActionHandler('play', controllerRef.current.videoElement.play);
    }
    return () => {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = undefined;

        navigator.mediaSession.setActionHandler("previoustrack", null);
        navigator.mediaSession.setActionHandler("nexttrack", null);
        // navigator.mediaSession.setActionHandler('pause', null);
        // navigator.mediaSession.setActionHandler('play', null);
      }
    };
  }, [episode, episodes])

  console.logDebug("video data", videoData);

  var unparsedAips = videoData.aips;

  var aips = {};

  var aip;

  function timecodeToSeconds(timecode) {
    var a = timecode.split(":");
    var seconds = parseInt(a[0]) * 3600 + parseInt(a[1]) * 60 + parseInt(a[2]);
    return seconds;
  }

  for (aip of unparsedAips) {
    aips[aip.type.name] = timecodeToSeconds(aip.timecode);
  }

  console.logDebug("aips", aips);

  var episodeImage = "";

  try {
    episodeImage =
      "https://proxy.benhong.me/corsproxy/?apiurl=" +
      []
        .concat(...episodeData.images.map((a) => a.imageInstances))
        .sort((a, b) => b.imageInfo.height - a.imageInfo.height)[0].imageInfo
        .fullPath +
      "?";
  } catch (err) {
    console.logDebug("no episode image");
  }

  React.useEffect(() => {
    if (controllerRef) {
      if (controllerRef.current) {
        window.vidController = controllerRef.current;
        const {
          // /** @type {shaka.Player} */ player,
          // /** @type {shaka.ui.Overlay} */ ui,
          /** @type {HTMLVideoElement} */ videoElement,
        } = controllerRef.current;
        if (videoElement) {
          console.logDebug("video element", videoElement);
          // videoElement.focus()
          videoElement.setAttribute("poster", episodeImage);
          videoElement.setAttribute("autoplay", true);
          function onTimeUpdate() {
            var played = videoElement.currentTime;
            if (
              videoElement.readyState !== 0 &&
              videoElement.readyState !== 1 &&
              played !== 0
            ) {
              // console.log('storing progress in db', { show: episodeData.showSlug, sequence: episodeData.sequence, time: played, duration: episodeData.duration })
              db.episodeProgress.put({
                show: episodeData.showSlug,
                sequence: episodeData.sequence,
                time: played,
                duration: episodeData.duration,
              });
              var currentStoredProgress = JSON.parse(localStorage.progress);
              if (
                played > episodeData.duration - 90 ||
                (aips["Start of Closing Credits"] &&
                  aips["Start of Closing Credits"] < played)
              ) {
                var nextEpisodeExists = false;
                for (var i of [...Array(6).keys()]) {
                  // console.log('checking if episode exists', i, episode, parseInt(episode) + 1 + i, episodes[parseInt(episode) + 1 + i])
                  if (episodes[parseInt(episode) + 1 + i]) {
                    currentStoredProgress[showName] = {
                      episode: parseInt(episode) + 1 + i,
                      time: 0,
                      data: episodes[parseInt(episode) + 1 + i],
                      date: Date.now(),
                      duration: episodes[parseInt(episode) + 1 + i].duration,
                    };
                    nextEpisodeExists = true;
                    break;
                  }
                }
                if (!nextEpisodeExists) {
                  const simulcasting =
                    episodes[Math.max(...Object.keys(episodes))].updateDate <
                    show.simulcastEndDate - 48 * 60 * 60 * 1000 &&
                    episodes[Math.max(...Object.keys(episodes))].updateDate +
                    6 * 24 * 60 * 60 * 1000 <
                    Date.now();
                  if (simulcasting) {
                    currentStoredProgress[showName] = {
                      episode: parseInt(episode) + 1,
                      time: 0,
                      date: Date.now(),
                      waiting: true,
                    };
                  } else {
                    currentStoredProgress[showName] = {
                      episode: 1,
                      time: 0,
                      date: Date.now(),
                      finished: true,
                    };
                  }
                }
              } else if (played > 10) {
                currentStoredProgress[showName] = {
                  episode: episodeData.sequence,
                  time: played,
                  data: episodeData,
                  date: Date.now(),
                  duration: episodeData.duration,
                };
              }
              localStorage.progress = JSON.stringify(currentStoredProgress);
            }
            if (aips["End of Opening Credits"]) {
              if (aips["Start of Opening Credits"]) {
                if (
                  played > aips["Start of Opening Credits"] &&
                  played < aips["End of Opening Credits"]
                ) {
                  if (
                    JSON.parse(localStorage.skipOpeningClosing || "false") &&
                    !skippedOpening.current
                  ) {
                    skippedOpening.current = true;
                    videoElement.currentTime = aips["End of Opening Credits"];
                  } else {
                    setShowOpeningSkip(true);
                  }
                } else {
                  setShowOpeningSkip(false);
                }
              } else {
                if (played < aips["End of Opening Credits"]) {
                  if (
                    JSON.parse(localStorage.skipOpeningClosing || "false") &&
                    !skippedOpening.current
                  ) {
                    skippedOpening.current = true;
                    videoElement.currentTime = aips["End of Opening Credits"];
                  } else {
                    setShowOpeningSkip(true);
                  }
                } else {
                  setShowOpeningSkip(false);
                }
              }
            }
            if (aips["Start of Closing Credits"]) {
              if (aips["End of Closing Credits"]) {
                if (
                  played > aips["Start of Closing Credits"] &&
                  played < aips["End of Closing Credits"]
                ) {
                  if (
                    JSON.parse(localStorage.skipOpeningClosing || "false") &&
                    !skippedClosing.current
                  ) {
                    skippedClosing.current = true;
                    videoElement.currentTime = aips["End of Closing Credits"];
                  } else {
                    setShowClosingSkip(true);
                  }
                } else {
                  setShowClosingSkip(false);
                }
              } else {
                if (played > aips["Start of Closing Credits"]) {
                  if (
                    JSON.parse(localStorage.skipOpeningClosing || "false") &&
                    !skippedClosing.current
                  ) {
                    nextEpisode();
                  } else {
                    setShowClosingSkip(true);
                  }
                } else {
                  setShowClosingSkip(false);
                }
              }
            }
          }
          async function resumeProgress() {
            console.log(
              "resuming progress current time:",
              videoElement.currentTime
            );
            if (!videoElement.currentTime) {
              window.goingToNext = false;
              var currentStoredProgress
              if (
                storedProgress[showName] &&
                storedProgress[showName].data &&
                storedProgress[showName].data.sequence === episodeData.sequence
              ) {
                currentStoredProgress = storedProgress[showName];
              } else {
                currentStoredProgress = await db.episodeProgress.get([
                  episodeData.showSlug,
                  episodeData.sequence,
                ]);
              }
              console.log("current progress", currentStoredProgress);
              if (currentStoredProgress) {
                if (
                  currentStoredProgress.time > episodeData.duration - 60 ||
                  (aips["End of Closing Credits"] &&
                    aips["End of Closing Credits"] < currentStoredProgress.time)
                ) {
                  console.log("episode already finished");
                  videoElement.currentTime = 0;
                } else {
                  if (aips["End of Opening Credits"]) {
                    if (aips["Start of Opening Credits"]) {
                      if (
                        currentStoredProgress.time >
                        aips["Start of Opening Credits"] &&
                        currentStoredProgress.time <
                        aips["End of Opening Credits"]
                      ) {
                        if (
                          JSON.parse(
                            localStorage.skipOpeningClosing || "false"
                          ) &&
                          !skippedOpening.current
                        ) {
                          skippedOpening.current = true;
                          videoElement.currentTime =
                            aips["End of Opening Credits"];
                        } else {
                          videoElement.currentTime = currentStoredProgress.time;
                          setShowOpeningSkip(true);
                        }
                      } else {
                        videoElement.currentTime = currentStoredProgress.time;
                        setShowOpeningSkip(false);
                      }
                    } else {
                      if (
                        currentStoredProgress.time <
                        aips["End of Opening Credits"]
                      ) {
                        if (
                          JSON.parse(
                            localStorage.skipOpeningClosing || "false"
                          ) &&
                          !skippedOpening.current
                        ) {
                          skippedOpening.current = true;
                          videoElement.currentTime =
                            aips["End of Opening Credits"];
                        } else {
                          videoElement.currentTime = currentStoredProgress.time;
                          setShowOpeningSkip(true);
                        }
                      } else {
                        videoElement.currentTime = currentStoredProgress.time;
                        setShowOpeningSkip(false);
                      }
                    }
                  } else {
                    videoElement.currentTime = currentStoredProgress.time;
                  }
                }
              } else {
                videoElement.currentTime = 0;
              }
            }
          }

          function onPlay() {
            setPlaying(true);
          }

          function onPause() {
            // window.lastMouseMove = Date.now();
            // setControlsOpen(true)
            // setTimeout(() => {
            //     if (Date.now() > (parseInt(window.lastMouseMove) + 2900)) {
            //         setControlsOpen(false)
            //     }
            // }, 3000);
            setPlaying(false);
          }

          videoElement.addEventListener("timeupdate", onTimeUpdate);
          // videoElement.ontimeupdate = onTimeUpdate;
          videoElement.addEventListener("durationchange", resumeProgress);
          // videoElement.ondurationchange = resumeProgress
          videoElement.addEventListener("ended", nextEpisode);
          // videoElement.onended = nextEpisode

          videoElement.addEventListener("play", onPlay);
          // videoElement.onplay = () => setPlaying(true)
          videoElement.addEventListener("pause", onPause);
          // videoElement.onpause = () => setPlaying(false)

          return () => {
            videoElement.removeEventListener("timeupdate", onTimeUpdate);
            videoElement.removeEventListener("durationchange", resumeProgress);
            videoElement.removeEventListener("ended", nextEpisode);
            videoElement.removeEventListener("play", onPlay);
            videoElement.removeEventListener("pause", onPause);
          };
        }
      }
    }
  }, [
    aips,
    controllerRef,
    episode,
    episodeData,
    episodes,
    showName,
    sourceUrl,
  ]);

  const sourcesChooser = React.useRef(null);
  const infoViewer = React.useRef(null);

  document.title =
    episodeData.showTitle +
    " " +
    episodeData.shortCode +
    " - " +
    episodeData.name;

  React.useEffect(() => {
    function onTouch(touchEvent) {
      const touch = touchEvent.touches[0];

      window.lastMouseMove = touchEvent.timeStamp;
      setControlsOpen(true);
      setTimeout(() => {
        if (Date.now() > parseInt(window.lastMouseMove) + 2900) {
          setControlsOpen(false);
        }
      }, 3000);

      if (
        Math.abs(touch.clientX - window.innerWidth / 2) >
        window.innerWidth / 10
      ) {
        if (touchEvent.timeStamp - window.lastTouch < 500) {
          console.log(controllerRef);
          document.documentElement.style.pointerEvents = "none";
          clearTimeout(window.enableTouchTimeout);
          window.enableTouchTimeout = setTimeout(() => {
            document.documentElement.style.pointerEvents = "auto";
          }, 500);
          if (touch.clientX < window.innerWidth / 2) {
            seekTenSecondsBack();
          } else {
            seekTenSecondsForward();
          }
        }
        window.lastTouch = touchEvent.timeStamp;
      }
    }

    window.addEventListener("touchstart", onTouch);

    return () => window.removeEventListener("touchstart", onTouch);
  }, []);

  React.useEffect(() => {
    window.lastMouseMove = Date.now();
    setTimeout(() => {
      if (Date.now() > parseInt(window.lastMouseMove) + 2400) {
        setControlsOpen(false);
      }
    }, 2500);
  }, []);

  console.logDebug("source url", sourceUrl);

  return (
    <Fade in>
      <div
        style={{
          backgroundColor: "black",
          position: "fixed",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          zIndex: 10,
          padding: "0px",
        }}
        onMouseMove={() => {
          window.lastMouseMove = Date.now();
          setControlsOpen(true);
          setTimeout(() => {
            if (Date.now() > parseInt(window.lastMouseMove) + 2900) {
              setControlsOpen(false);
            }
          }, 3000);
        }}
        onMouseOut={() => setControlsOpen(false)}
      >
        <Fade
          in={controlsOpen || !playing}
          style={{ pointerEvents: "none" }}
          timeout={1000}
        >
          <div
            style={{
              width: "100%",
              pointerEvents: "none",
              zIndex: 5,
              overflow: "hidden",
              position: "fixed",
              background: "linear-gradient(black, transparent)",
              display: "flex",
              alignItems: "stretch",
            }}
          >
            <div
              ref={infoViewer}
              style={{
                flexGrow: 8,
                marginTop: window.isMobile ? "20px" : "25px",
                zoom: window.isMobile ? 0.9 : 1,
              }}
            >
              <div
                style={{
                  pointerEvents: "auto",
                  marginLeft: "10px",
                  float: "left",
                }}
              >
                <IconButton
                  style={{ position: "relative" }}
                  onClick={() => {
                    if (history.length <= 2) {
                      history.replace("/shows/" + showName);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
                {/* <IconButton style={{ position: 'relative' }} onClick={() => history.push('/shows/' + showName)}>
                                {orientation.includes("landscape") ? <ScreenLockPortraitIcon fontSize="large" /> : <ScreenLockLandscapeIcon fontSize="large" />}
                            </IconButton> */}
              </div>
              <div
                style={{
                  pointerEvents: "none",
                  marginLeft: "10px",
                  float: "left",
                  width: "calc(100% - 80px)",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ color: "rgba(255,255,255,.9)" }}
                >
                  You&apos;re watching:
                </Typography>
                <Typography
                  variant={show.name.length > 40 ? "h4" : "h3"}
                  style={{ fontWeight: 600 }}
                >
                  {episodeData.showTitle}
                </Typography>
                {!show.isMovie && (
                  <Typography variant="h5">
                    {episodeData.shortCode}
                    {" - "}
                    {episodeData.name}
                  </Typography>
                )}

                <div
                  ref={sourcesChooser}
                  style={{ display: "flex", columnGap: "20px" }}
                >
                  {Object.keys(sources).length === 1 ? (
                    <Typography variant="h6">{source.language}</Typography>
                  ) : (
                    <Select
                      className={classes.select}
                      value={source.language}
                      onChange={handleLanguageChange}
                      style={{ pointerEvents: "auto" }}
                    >
                      {Object.keys(sources).map((languageName) => (
                        <MenuItem value={languageName}>{languageName}</MenuItem>
                      ))}
                      {/* <MenuItem value="English">English</MenuItem>
                                        <MenuItem value="Japanese">Japanese</MenuItem> */}
                    </Select>
                  )}

                  {[2100, 2000].includes(source.res) ? null : (
                    <Select
                      className={classes.select}
                      value={source.res}
                      onChange={handleQualityChange}
                      style={{ pointerEvents: "auto" }}
                    >
                      {(sources[source.language]
                        ? sources[source.language]
                        : Object.values(sources)[0]
                      ).map((videoSource) => (
                        <MenuItem value={videoSource.res}>
                          {videoSource.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Slide direction="up" in={showOpeningSkip} timeout={500} mountOnEnter unmountOnExit>
          <Button
            variant="contained"
            style={{
              position: "fixed",
              bottom: "90px",
              transform: !(controlsOpen || !playing) && "translateY(60px)",
              left: "2vw",
              zIndex: 1000,
            }}
            onClick={() => {
              controllerRef.current.videoElement.currentTime =
                aips["End of Opening Credits"];
            }}
            color="primary"
          >
            Skip opening
          </Button>
        </Slide>

        <Slide direction="up" in={showClosingSkip} mountOnEnter unmountOnExit>
          <Button
            variant="contained"
            style={{
              position: "fixed",
              bottom: "90px",
              transform: !(controlsOpen || !playing) && "translateY(60px)",
              left: "2vw",
              zIndex: 1000,
            }}
            onClick={() => {
              controllerRef.current.videoElement.currentTime = aips[
                "End of Closing Credits"
              ]
                ? aips["End of Closing Credits"]
                : (nextEpisode(), 0);
            }}
            color="primary"
          >
            Skip closing
          </Button>
        </Slide>

        <ShakaPlayer
          autoPlay
          src={sourceUrl}
          ref={controllerRef}
          className={classes.player}
        />
      </div>
    </Fade>
  );
}
