import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React from "react";


export default function Fallback() {
    return (
        <Fade in>
            <div style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                paddingTop: '20vh',
                paddingInline: '20vw',
            }}>
                <RemoveCircleOutlineIcon style={{
                    margin: "auto", fontSize: '20vh'
                }} />
                <Typography variant="h2" style={{ margin: "auto", marginTop: 10, fontWeight: '600', marginLeft: 30, fontFamily: "fieldwork, sans-serif" }}>
                    Uh oh...
                </Typography>
                <Typography variant="h6" style={{ margin: "auto", marginTop: 10, fontFamily: "fieldwork, sans-serif" }}>
                    Something went <span style={{ fontStyle: "italic" }}>really</span> wrong.
                    <br />
                    <br />
                    Don&apos;t worry though! Details of your error have already been sent to us so we can figure out how to fix it.
                    Fill out the form to help us figure it out even quicker
                </Typography>
            </div>
        </Fade>
    );
}
